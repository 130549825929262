// adicionar também em config\locales\models\table_lists.pt-BR.yml
module.exports = {
  currency: {
    abreviation: 'BRL',
    symbol: 'R$',
    decimals: 2,
  },
  general: {
    rule: 'Regra',
    coverage_by_curve: 'Cobertura por Curva',
    coverage_by_class: 'Cobertura por Classe',
    multiple_cargo_deliveries: 'Múltiplas Cargas de Entrega',
    store_cluster: 'Agrupamento de Loja',
    enable_mirrored_simulation_schedules: 'Habilitar simulação de agendas espelhadas',
    adding_products: 'Adicionando produtos',
    add_products: 'Adicionar produtos',
    type: 'Tipo',
    add: 'Adicionar',
    actions: 'Ações',
    purchase_price_simulator: 'Simulação de Preço de Compra',
    schedule_name: 'Agenda',
    actual_stock: 'Estoque Atual',
    actual_month: 'Mês atual',
    actual_year: 'Ano atual',
    add_tolerance: 'Adicionar Tolerância',
    adjusted_sale: 'Venda ajustada',
    adjusted_sale_abrev: 'V. ajust',
    adjusts: 'Ajustes de Inventário',
    rounding_percentages: 'Percentual para arredondamento de pedido',
    safety_stock_days: 'Dias de estoque de segurança',
    address: 'Endereço',
    address_number: 'Número',
    zip_code: 'CEP',
    country: 'País',
    room_size: 'Tamanho do salão',
    manager_name: 'Nome do gerente',
    manager_email: 'Email do gerente',
    activate: 'Ativar',
    inactivate: 'Inativar',
    already_bring_dc: 'Sempre trazer o centro de distribuição',
    average_stock: 'Estoque médio',
    average_unit_cost: 'Custo Médio',
    apply_in_generate_order: 'Aplicar na geração de pedidos',
    maximum: 'Máximo',
    avg_actual_sales_90: 'Média de vendas reais últimos 90 dias',
    avg_adjusted_sales: 'Média de vendas ajustadas',
    avg_actual_sales_90_purchase_unit: 'Média diária de vendas (unid. compra)',
    avg_adjusted_sales_purchase_unit: 'Média de vendas ajustadas (unid. compra)',
    maximum_coverage_days_by_actual_sales: 'Máximo de dias de cobertura por vendas reais',
    maximum_coverage_days_by_actual_sale: 'Máximo de dias de cobertura por vendas reais',
    create_cargo_multiple_pallets: 'Formar carga com múltiplos de paletes',
    cargo_round_percentage: 'Percentual de arredondamento da carga',
    default_pallet_rounding: 'Arredondamento de palete padrão',
    value_type: 'Tipo do Valor',
    list_all: 'Listar Todos',
    all: 'Todos',
    average: 'Média',
    branch_line: 'Ramal',
    breaks: 'Quebras',
    billings: 'Faturamento',
    billing_abbreviation: 'Fat.',
    removing_pending_orders: 'Removendo Pedidos Pendentes',
    business_description: 'Descrição comercial',
    active_for_purchase: 'Ativo para compra',
    active_for_sale: 'Ativo para venda',
    active_on_mix: 'Ativo no mix',
    updating_systematic: 'Atualizando Sistemática',
    business_groups: 'Grupo Econômico',
    buyer: 'Comprador',
    movement_types: 'Classificação dos movimentos',
    name: 'Nome',
    stores_view: 'Visão por Loja',
    supplier_view: 'Visão por Fornecedor',
    quotation: 'Cotação',
    came_in_line: 'Entrou em linha',
    fem_all: 'Todas',
    cancel: 'Cancelar',
    changed: 'Alterado',
    update: 'Atualizar',
    changed_coverage: 'Alterar cobertura',
    charts: 'Gráficos',
    chart: 'Gráfico',
    class: 'Classe',
    draft_order_item_block_justification: 'Justificativa de bloqueio de item',
    for_stores: 'Para Loja(s)',
    code: 'Código',
    type_of_block: 'Tipo de bloqueio',
    classification: 'classificação',
    today: 'Hoje',
    process_following_day: 'Processar dia seguinte da importação (ex.: importou dia 10, processar o dia 11)',
    until_today: 'Processar até hoje',
    process_data: 'Processar Previsão',
    clear: 'Limpar',
    clear_selection: 'Limpar seleção',
    click_to_open: 'clique para abrir',
    click_to_hide: 'Clique para ocultar',
    click_to_show: 'Clique para exibir',
    click_to_edit: 'clique para editar',
    click_to_expand: 'Clique para expandir',
    close: 'Fechar',
    closed: 'Fechado',
    closure: 'Fechamento',
    close_chidren_products: 'Fechar produtos filhos',
    column_adjusts: 'Ajuste das colunas',
    confirm: 'Confirmar',
    complete_order: 'Concluir pedido',
    complete_product: 'Concluir produto',
    consider_deadline: 'Considerar prazo de entrega',
    consider_exposure: 'Considerar exposição',
    update_schedule: 'Atualizar agenda?',
    consider_derivatives: 'Considerar Derivados',
    coverage_days: 'Dias de cobertura',
    coverage: 'Cobertura',
    consumption: 'Consumo',
    coverage_consider_delivery_day: 'Cobertura Considera Dia da entrega?',
    children_products: 'Produtos Filhos',
    date: 'Data',
    day: 'Dia',
    days: 'Dias',
    combo_considering_coverage: 'Cobertura',
    daily_frequency: 'Frequência Diária',
    discount: 'Desconto',
    simulate: 'Simular',
    week_frequency: 'Frequência Semanal',
    month_frequency: 'Frequência Mensal',
    daily_orders_allowed: 'Visualizar Sugestão de Pedidos',
    data_ordered: 'Ordenação dos dados',
    default_list_configuration: 'Configuração padrão de listagem',
    decreasing: 'decrescente',
    description: 'Descrição',
    stores_block_for_purchase: 'Bloqueados para compra',
    added: 'Adicionado',
    not_added: 'Não adicionado',
    deadline: 'Prazo Entrega',
    secondary_deadline: 'Prazo Entrega (informada)',
    secondary_full_capacity_pallet: 'Qtd palete',
    secondary_number_of_layers: 'Qtd de camadas',
    ordering_suggestions: 'Sugestões de Pedidos',
    details: 'Detalhes',
    deliveries: 'Entregas',
    delivery_prediction_date: 'Data da Previsão de Entrega',
    delivery_date: 'Data de entrega',
    deliveries_and_devolutions: 'Entregas e Devoluções',
    manipulation_time: 'Tempo de manipulação',
    devolutions: 'Devoluções',
    distributor: 'Distribuidor',
    download_csv: 'Baixar em CSV',
    download_xlsx: 'Baixar em XLSX',
    download_pdf: 'Baixar em PDF',
    download_in: 'Baixar em',
    duration: 'Duração',
    edit: 'Editar',
    erp_synchronize: 'Sincronizar ERP',
    ean: 'EAN',
    entrance: 'Entrada',
    email: 'Email',
    estimated_quantity: 'Quantidade Estimada',
    expiration_date_br: 'Data de<br />expiração',
    shipping_time_br: 'Prazo de entrega',
    export: 'Exportação',
    filters: 'Filtros',
    frequency: 'Frequência',
    first: 'Primeiro',
    filter_of: 'filtrado de',
    go_to: 'Ir para',
    greatness: 'Grandeza',
    growing: 'crescente',
    group: 'Grupo',
    department: 'Departamento',
    horary: 'Horário',
    hide_column: 'Ocultar coluna',
    hidden_columns: 'Colunas ocultas',
    horizontal: 'Horizontal',
    order_item_blocked: 'Bloquear/Desbloquear Item',
    records_in_total: 'registro(s) no total',
    active: 'Ativo',
    inactive: 'Inativo',
    initial: 'inicial',
    informed_sales_abrev: 'V. Inf',
    inventory: 'Inventário',
    inventory_and_breaks: 'Inventário e Quebras',
    inventory_turnover: 'Giro de Estoque',
    keep_order_changes: 'Manter Alterações do Pedido',
    production_schedule: 'Produção',
    zero_suggestion: 'Zerar Produtos de Fornecimento Alternativo',
    last: 'Último',
    last_buy_unit_cost: 'Último Preço de Custo',
    last_entry_unit_cost: 'Custo Última Entrada',
    last_sale_price: 'Último Preço de Venda',
    last_sale_purchase_br: 'Último preço de compra',
    last_month: 'Último mês',
    last_suggestion_date: 'Data da Última Sugestão',
    last_week: 'Última semana',
    last_weekend: 'Último final de semana',
    last_year: 'Ano anterior',
    list: 'Listagem',
    level: 'Nível',
    year_before_last: 'Ano retrasado',
    launch: 'Lançamentos',
    rounding: 'Arredondamento',
    primary_sale: 'Lançamento',
    linear_front: 'Exposição ERP',
    loading: 'Carregando...',
    market_structure: 'Estrutura Mercadológica',
    market_structures: 'Estruturas Mercadológicas',
    maintenance: 'Esta tela está em manutenção.',
    margin: 'Margem',
    transfer_unit_quantity: 'Quantidade da transferência',
    transfer_unit: 'Unidade de transferência',
    display_rack_front_line_capacity: 'Exposição',
    display_rack_capacity: 'Capacidade da Exposição',
    minimum: 'Mínimo',
    minimum_stock: 'Estoque Mínimo',
    minimum_order: 'Pedido Mínimo',
    maxima: 'Máxima',
    minima: 'Mínima',
    monitoring: 'Monitoramento',
    month: 'Mês',
    monthly_sale: 'Venda Mensal',
    mobile: 'Celular',
    next: 'Próximo',
    new: 'Novo',
    news: 'Novidades',
    new_column_need_ordered: 'coluna nova, precisa ser ordenada',
    next_order: 'Próximo Pedido',
    no_prediction: 'Sem Previsão',
    purchase_type: 'Tipo de Unidade de Compra',
    trade_name: 'Nome Fantasia',
    sale_unit: 'Unidade de Venda',
    negotiated_price: 'Preço negociado',
    negotiated_price_sales_unit: 'Preço negociado Unid. Venda',
    negotiated_price_purchase_unit: 'Preço negociado Unid. Compra',
    change_negotiated_price: 'Alterar preço negociado',
    truck: 'Caminhão',
    truck_load: 'Carga de caminhão',
    cross_dock_rounding: 'Arredondamento Cross Dock',
    order_in_purchase_unit: 'Pedido (unid. compra)',
    order_in_sales_unit: 'Pedido Unid. Venda',
    change_order_in_purchase_unit: 'Alterar Pedido Unid. Compra',
    of: 'de',
    of_billings: 'do faturamento',
    of_products: 'dos produtos',
    hide: 'Ocultar',
    hide_costs: 'Ocultar custos',
    hide_indicators: 'Ocultar indicadores',
    hide_breaks: 'Ocultar quebras',
    order: 'Sugestão de pedido',
    show_schedule_changes: 'Alterações na agenda',
    order_date: 'Data do Pedido',
    order_calendar: 'Calendário de Pedidos',
    observations: 'Observações',
    opening: 'Abertura',
    open_children_products: 'Abrir produtos filhos',
    operational_ruptures: 'Rupturas Operacionais',
    out_of_line: 'Saiu de linha',
    original_transfer_schedule_next_visit: 'Data da Agenda Original',
    open_apply_systematics_modal: 'Abrir modal de Aplicar Sistemática',
    parameters: 'Parâmetros',
    pay_day: 'Dias para pagamento',
    parent_name: 'Produto Base',
    password: 'Senha',
    new_password: 'Nova senha',
    password_confirmation: 'Confirmação de senha',
    new_password_confirmation: 'Confirmação de nova senha',
    periodicity: 'Frequência',
    ddv: 'Dias de Venda',
    ddv_with_buy: 'Dias de Venda com Compra',
    pending_orders: 'Pedidos Pendentes',
    percent_sale: '% Venda',
    pending_order_sale_unit: 'Ped. Pendente Unid. Venda',
    pending_order_purchase_unit: 'Ped. Pendente Unid. Compra',
    next_order_date: 'Data do Próx. Pedido',
    percent_sale_of_last_month: '% sobre a venda do mês anterior',
    percentage: 'Percentual',
    percentage_over_days: 'Percentual sobre dias',
    manual_cargo: 'Formação Múltipla de Carga Manual',
    seasonal: 'Sazonal',
    seasonal_products_chart: 'Gráfico de Produtos Sazonais',
    boxes: 'Caixas',
    permit_app_mobile: 'Permite app mobile',
    position: 'Posição',
    price_table: 'Tabela de Preços',
    promotions_table: 'Tabela de Promoções',
    mirrored_product_store: 'Produto espelhado na loja',
    promotion_value: 'Valor da promoção',
    promotion_created_by_erp: 'Promoção criada por ERP',
    price_name: 'Nome do preço',
    initial_date: 'Data inicial',
    expiration_type: 'Tipo de expiração',
    promotion_code: 'Código da promoção',
    buyers: 'Compradores',
    percentage_required: 'Percentual obrigatório',
    market_structure_required: 'Estrutura Mercadológica obrigatória',
    prices_and_costs: 'Preços e Custos',
    prediction: 'Previsão',
    previous: 'Anterior',
    projection: 'Projeção',
    product: 'Produto',
    product_quantity_abreviation: 'Qtd. de Produtos',
    product_code_abreviation: 'Cód. Produto',
    product_store_blocks: 'Bloqueios',
    proccessing: 'Processando...',
    promotion: 'Promoção',
    promotion_name: 'Nome da promoção',
    promotion_sale: 'Venda em promoção',
    promotion_days: 'Dias em promoção',
    product_history: 'Histórico do Produto',
    purchase_unit: 'Unidade de Compra',
    purchase_unit_br: 'Unidade<br />compra',
    purchase_unit_abreviation: 'Unid. Compra',
    quantity_days: 'Quantidade de dias',
    quantity_sku_abbreviation: 'Qtd Skus',
    quantity_in_purchase_unit: 'Quantidade na Embalagem de Compra',
    quantity_in_purchase_unit_br: 'Qtd. na embalagem<br />de compra',
    quantity_last_suggestion: 'Quantidade da Última Sugestão',
    quantity_in_percentage: 'Quantidade (em %)',
    quantity: 'Quantidade',
    quantity_package_abreviation: 'Qtd. embalagem',
    rate_rupture: 'Taxa de Ruptura Comercial',
    rate_operational_rupture: 'Taxa de Ruptura Operacional',
    real_sale: 'Venda Real',
    real_sales_abrev: 'V. real',
    regional: 'Regional',
    record_pluralize: 'registro(s)',
    records: 'Registros',
    release_request_approver: 'Aprovação de Solicitação de Liberação',
    functional_record: 'Registro Funcional',
    suspended: 'Suspenso',
    regenerating_order: 'Regerando o pedido',
    regenerating_by_data_change: 'Regerando por atualização nos dados',
    rupture: 'Ruptura',
    ruptures: 'Rupturas Comerciais',
    rupture_rate: 'Índice de Ruptura',
    retail_unit_price: 'Preço Varejo',
    role_type: 'Perfil',
    sale: 'Venda',
    sale_prediction: 'Previsão de venda',
    sales: 'Vendas',
    sales_prediction: 'Vendas/Previsão',
    sales_unit: 'Unidade de Venda',
    rupture_for_x_class: 'Considerar ruptura para a classe X',
    order_for_all_suppliers_of_product_full_text:
      'Permitir gerar pedido para fornecedores alternativos do produto ' +
      '(gerando pedido para produtos no qual o fornecedor é o principal e no qual o fornecedor é alternativo)',
    configurations_synchronization: 'Configuração de Sincronização',
    configurations_password_update_time: 'Quantidade de dias para atualização de senha',
    password_update: 'Atualização de senha',
    prediction_error_margin: 'Margem de Erro (em %)',
    prediction_coefficients: 'Coeficientes para SMAPE na predição',
    seasonal_products: 'Produtos Sazonais',
    show_tuition_notice: 'Exibir alerta de inadimplência',
    api_url: 'Endereço de Acesso',
    api_key: 'Autenticação',
    configurations_prediction: 'Configuração de Predição',
    configurations_order: 'Configuração de Pedido',
    quotation_api: 'API Cotação',
    api_schedule_url: 'API Agenda',
    order_calc_by_coverage_days_option: 'Cálculo no pedido quando tiver dias de cobertura',
    order_calc_by_coverage_days_options: {
      prediction: 'Previsão de venda',
      avg_adjusted_sales: 'Média da venda ajustada',
      optional_selling_media_as_default: 'Opcional (Média de Venda como Padrão)',
    },
    order_pending_orders_until_date: 'Busca dos pedidos pendentes',
    order_pending_orders_until_date_options: {
      all: 'Todos',
      coverage_initial_date: 'Até a data inicial da cobertura',
      coverage_final_date: 'Até a data final da cobertura',
    },
    unity_transfer_in_package: 'Unidade de Transferencia em Unidade de Compra? (CD -> Loja)',
    order_calc_by_coverage_days_consider_safety_stock: 'Considerar estoque de segurança quando tiver dias de cobertura',
    consider_deadline_on_orders: 'Considerar prazo de entrega quando dias de cobertura estiver preenchido',
    validate_price_on_orders: 'Validar preço maior que zero na geração do pedido',
    permit_change_negotiated_price_on_order: 'Permite alterar preço no pedido',
    consider_blocked_product_in_order: 'Considerar produtos bloqueados',
    allow_remove_transfer_pending_order: 'Permitir remover pedido pendente transferência',
    always_send_blocked_product_on_order: 'Sempre enviar produto bloqueado',
    force_apply_systematic: 'Forçar aplicação de sistemática',
    close_order_by_filtering: 'Fechar pedido considerando filtragem',
    show_field_delivery_date: 'Exibir campo data de entrega',
    include_disabled_dc: 'Incluir centros de distribuição desabilitados',
    sale_in_quantity: 'Vendas em quantidade',
    sales_unit_abreviation: 'Unid. Venda',
    save: 'Salvar',
    regenerate_schedule: 'Recalcular',
    transfer_schedule_today: 'Transferir para hoje',
    transfer_schedule: 'Transferir',
    duplicate_schedule: 'Duplicar',
    security_stock: 'Estoque de Segurança',
    safety_stock_kikker: 'Estoque de Segurança Kikker',
    search: 'Buscar',
    select: 'Selecione...',
    select_date: 'Selecione a data',
    sector: 'Setor',
    service_level: 'Nível de Serviço',
    send_request: 'Enviar solicitação',
    sales_1_month_ago: 'Total venda -1 mês (unid. compra)',
    sales_2_month_ago: 'Total venda -2 meses (unid. compra)',
    sales_3_month_ago: 'Total venda -3 meses (unid. compra)',
    forecast_stock: 'Estoque previsto',
    current_order_value: 'Valor pedido atual',
    coverage_suggested_by_kikker: 'Cobertura sugerida pela Kikker',
    current_stock_days: 'Dias de estoque atual',
    mean_daily_adjusted_sale: 'Venda média ajustada (unid. venda)',
    changed_order: 'Alterado manualmente',
    icon_change_order_plus: 'Alterado +',
    icon_change_order_minus: 'Alterado -',
    schedule: 'Agenda',
    schedules: 'Agendas',
    schedule_date: 'Data da Agenda',
    similars: 'Similares',
    similars_products: 'Produtos Similares',
    products: 'Produtos',
    promotional_unit_price: 'Preço Promocional',
    show: 'Mostrar',
    showing_of: 'Mostrando do',
    skip_all_blocks_of_purchase: 'Desconsiderar bloqueio de compra de todos os produtos',
    skip_blocks_of_purchase: 'Desconsiderar bloqueio de compra',
    skip_block_of_purchase_modal_title:
      'Tem certeza que deseja desconsiderar o bloqueio de compra do ERP de todos os itens?',
    skip_block_of_purchase_modal_content: 'Ao concluir essa opção, ela não haverá como ser desfeita.',
    sku: 'SKU',
    skus: 'SKUs',
    count: 'Quantidade',
    summary: 'Resumo',
    recidivism: 'Reincidência',
    sales_unit_quantity: 'Qtd. Unid. Venda',
    store: 'Loja',
    stores: 'Lojas',
    close_order_rules: 'Regras de pedido de compra',
    order_configuration: 'Botões do Pedido',
    store_clusters: 'Agrupamento de Lojas',
    minimum_order_by_store: 'Pedido mínimo por loja',
    maximum_coverage_days: 'Máximo de dias de cobertura',
    percentage_up: 'Percentual cobertura para cima',
    percentage_up_and_down: 'Percentual cobertura para cima e para baixo',
    percentage_down: 'Percentual cobertura para baixo',
    rule_name: 'Nome da Regra',
    rule_kind: 'Tipo da Regra',
    warning: 'Alerta',
    blocking: 'Bloqueio',
    approving: 'Aprovação',
    store_code: 'Código da Loja',
    opening_date: 'Data de Abertura',
    alias_for_sales_accounting: 'Apelido Venda',
    create_cargo: 'Formação de Carga',
    stock_sale_unit: 'Estoque (unid. venda)',
    stock_purchase_unit: 'Estoque (unid. compra)',
    status: 'Status',
    standard_sorting: 'Ordenação padrão',
    sort_by: 'Ordenar por',
    distribution_system: 'Sistemática de distribuição',
    sub_group: 'Sub Grupo',
    supplier: 'Fornecedor',
    suppliers: 'Fornecedores',
    historical_data_descending_order: 'Pedido histórico do produto - Ordem decrescente',
    erp_control_reopen_available: 'Controle de reabertura de pedido pelo ERP',
    allow_send_order_by_base64: 'Habilitar envio de pedido por base64',
    has_get_order_code: 'Necessita de API para pegar codigo de pedido do erp',
    supplier_code: 'Código Fornecedor',
    supplier_code_abrev: 'Cód. Fornecedor',
    supplier_name: 'Nome do Fornecedor',
    supply_abrev: 'Abast.',
    supply_type: 'Sistemática de Abastecimento',
    supply_type_abbr: 'Sist. Abastecimento',
    supply_details: 'Detalhes do Abastecimento',
    surplus_stock: 'Estoque Excedente',
    surplus_stock_value: 'Valor Excedente do Estoque',
    to_select: 'Selecionar...',
    searching: 'Procurando...',
    systematic: 'Sistemática',
    front_line_capacity_alternative: 'Exposição Kikker',
    stock: 'Estoque',
    structure: 'Estrutura',
    table_configurations: 'Configurações da Tabela',
    telephone: 'Telefone',
    title: 'Título',
    to_filter: 'Filtrar',
    total: 'Total',
    total_virtual_stock_alert: 'Total de itens',
    total_virtual_stock_alert_section: 'Total de itens M.Alto + Alto',
    total_sell_in: 'Total sell in',
    total_minus_sell_in: 'Total menos sell in',
    total_in_pallets: 'Total de Paletes',
    total_in_number_of_boxes_per_ballast: 'Total de camadas',
    total_order_in_purchase_unit: 'Total em Unid. Compras',
    total_sum: 'Soma Total',
    total_sell_in_sum: 'Soma Total Sell In',
    total_minus_sell_in_sum: 'Soma Total Menos Sell In',
    total_in_pallets_sum: 'Soma de Paletes Total',
    total_in_number_of_boxes_per_ballast_sum: 'Soma de Camadas Total',
    total_weight_sum: 'Soma de peso total',
    total_volume_sum: 'Soma de volume total',
    total_order_in_purchase_unit_sum: 'Soma das Unid. Compras Total',
    total_transfer_stock: 'Valor Total Estoq. de Transf.',
    total_stock_value: 'Valor Total do Estoque',
    calculation_method: 'Método de cálculo',
    unit: 'Unidade',
    unit_price: 'Preço unitário',
    business_configuration: 'Configuração do Grupo Econômico',
    digital_advisor: 'Assessor Digital',
    unit_price_sale_unit_br: 'Preço unitário<br />(unid. de venda)',
    units: 'Unidades',
    unit_value_abrev: 'V. Unitário',
    configuration_api_delivery_order: 'Volta do Pedido',
    api_delivery_order: 'API Volta do pedido',
    grouping_type: 'Tipo de Agrupamento',
    grouping_type_options: {
      none_grouping: 'Sem agrupamento',
      'systematic;store_id': 'Sistemática e Loja',
    },
    delivery_order: 'Entrega do pedido',
    delivery_order_items_with_zero: 'Entregar itens do pedido com quantidade zero',
    consider_unity_transfer: 'Considerar unidade de transferência',
    system_default_data: 'Dados padrões do sistema',
    default_days_to_use_combat: 'Dias padrão para usar o combate',
    get_order_code: 'API para pegar codigo de pedido do erp',
    unit_management_cost: 'Custo Gerencial',
    until: 'até',
    unrealized_billing: 'Faturamento não realizado',
    update_schedule_question: 'Atualizar agenda?',
    origin: 'Origem',
    draft_order_audit: 'Alterações no pedido',
    value: 'Valor',
    original_value: 'Valor original',
    percentage_increase_in_total: 'Porcentagem de aumento no total',
    tolerance: 'Tolerância',
    tolerance_type: 'Tipo de Tolerância',
    values: 'Valores',
    variation: 'Variação atual',
    variation_past_years: 'Variação',
    vertical: 'Vertical',
    visit_date: 'Data da visita',
    v_t: 'V.T.',
    total_weight: 'Peso Total',
    total_volume: 'Volume Total',
    weight: 'Peso',
    volume: 'Volume',
    consumption_current_month: 'Total consumo mês atual',
    consumption_last_30_days: 'Total consumo últimos 30 dias',
    consumption_1_month_ago: 'Total consumo -1 mês',
    consumption_2_month_ago: 'Total consumo -2 mês',
    consumption_3_month_ago: 'Total consumo -3 mês',
    consumption_current_month_purchase_unit: 'Total consumo mês atual (Unid. compra)',
    consumption_last_30_days_purchase_unit: 'Total consumo últimos 30 dias (Unid. compra)',
    consumption_1_month_ago_purchase_unit: 'Total consumo -1 mês (Unid. compra)',
    consumption_2_month_ago_purchase_unit: 'Total consumo -2 mês (Unid. compra)',
    consumption_3_month_ago_purchase_unit: 'Total consumo -3 mês (Unid. compra)',
    v_e: 'V.E.',
    extensions: 'Extensões',
    week_days: 'Dias da semana',
    weekly_sale_in: 'Venda semanal em',
    without_prevision: 'Sem Previsão',
    with_stock_abbreviation: 'C/Estoque',
    wholesale_unit_price: 'Preço atacado',
    product_complete: 'Produto concluído',
    closing_order: 'Fechando o pedido',
    remove_item: 'Remover Item',
    remove: 'Remover',
    yes: 'Sim',
    no: 'Não',
    sdm_wholesale_unit_price: 'Preço atacado',
    sdm_retail_unit_price: 'Preço varejo',
    sdm_gross_sales_unit_price: 'Preço bruto',
    sdm_promotional_unit_price: 'Preço promocional',
    reference_data: 'Dado de referência',
    return_item: 'Voltar Item no Pedido',
    change_prices: 'Alterar preços',
    buy_question: 'Comprar?',
    sale_question: 'Vender?',
    mix_question: 'No Mix?',
    order_loading: 'Ordem...',
    yesterday: 'Ontem',
    week_abreviation: 'Sem.',
    weekly_sale: 'Venda Semanal',
    new_supplier: 'Novo fornecedor',
    is_alternative_supplier: 'Fornecedor Alternativo',
    is_not_alternative_supplier: 'Não é um Fornecedor Alternativo',
    not_alternative_supplier: 'Este fornecedor não é alternativo, continuar?',
    draft_order_item: 'Item do pedido',
    sale_unit_par: '(unid. venda)',
    purchase_unit_par: '(unid. compra)',
    suggested_par: '(sugerida)',
    actual_par: '(atual)',
    waiting: 'aguardando',
    front_line_capacity: 'Capacidade da exposição (un. venda)',
    rounding_percentage: 'Arredondamento da Unid. de Compra',
    rounding_for_store: 'Arredondamento por Loja',
    order_unit: 'Tipo de compra',
    type_order: 'Tipo de pedido',
    schedule_style: 'Tipo de agenda',
    simulation_schedule: 'Simulação',
    default_schedule: 'Normal',
    blank_schedule: 'Em branco',
    round_order_unit: 'Arredondamento da unidade do pedido',
    // full_capacity_pallet: 'Unidade do pedido em unidade de compra',
    rounding_factor_in_purchase: 'Fator de multiplicação de compra',
    rounding_factor_in_sale: 'Fator de multiplicação de venda',
    pallet: 'Palete',
    layer: 'Camada',
    pallet_or_layer: 'Palete ou camada',
    multi: 'Multiplo',
    order_number: 'Número do pedido',
    change_for_all: 'Alterar para todos',
    synchronize_erp: 'Sincronizar ERP',
    job_managers_abbr: 'JMs',
    request: 'Requisição',
    close_warning:
      'Ao forçar o fechamento deste modal é possível que o processo seja' +
      ' interrompido antes da conclusão, portanto é sugerido que os dados do pedido sejam verificados.',
    response: 'Resposta',
    click_here: 'Clique aqui',
    and_send_the_informations_to_support: 'e passe as informações ao suporte',
    slow_process: 'Esta demorando?',
    cross_docking: 'Cross Docking',
    systematic_erp: 'Sistemática ERP',
    secondary_systematic: 'Sistemática informada',
    sell_in: 'Sell In',
    distribution_center: 'Centro de Distribuição',
    running_for: 'Rodando á',
    second: 'segundo',
    seconds: 'segundos',
    minute: 'minuto',
    minutes: 'minutos',
    hour: 'hora',
    hours: 'horas',
    and: 'e',
    previous_product: 'Produto anterior',
    next_product: 'Próximo produto',
    delete: 'Apagar',
    reopen_order: 'Reabrir pedido',
    synced_at: 'Sincronizado em',
    disabled_beacause_has_closed_orders: 'Desabilitado, pois tem pedido fechado.',
    contract_negotiations: 'Contratos negociados',
    participation: 'Participação',
    classification_configs: 'Configuração da Classificação de Classes',
    product_family: 'Família',
    product_families: 'Famílias',
    client: 'Cliente',
    product_launch_time: 'Tempo de lançamento de produto',
    deadline_erp: 'Prazo de entrega ERP',
    schedule_deadline: 'Prazo de entrega (agenda)',
    automatic_purchase_items: ' itens de compra automática',
    automatic_purchase: 'Compra Automática',
    removed_product_store: 'Produto Loja removido',
    order_deadline: 'Prazo de entrega (pedido)',
    max_deadline: 'Maior prazo de entrega (dos itens)',
    sending_erp: 'Enviando pedido para o ERP',
    awaiting_erp: 'Aguardando retorno do ERP',
    finished_delivery_erp: 'Finalizada entrega no ERP',
    general_error: 'Erro geral no pedido',
    partial_error: 'Erro parcial no pedido',
    action: 'Ação',
    signal: 'Sinal',
    remove_store: 'Remover loja',
    remove_stores: 'Remover lojas',
    return_store: 'Voltar loja',
    return_stores: 'Voltar lojas',
    process_with_errors: 'Processos com erro',
    suggested_quantity: 'Quantidade sugerida',
    function: 'Função',
    full_capacity_pallet: 'Capacidade total do palete',
    full_capacity_pallet_erp: 'Capacidade total do palete ERP',
    number_of_boxes_per_ballast: 'N. de caixas por lastro',
    number_of_boxes_per_ballast_erp: 'N. de caixas por lastro ERP',
    number_of_layers: 'N. de camadas',
    layers: 'Camadas',
    price_simulation: 'Simulador de preço',
    number_of_layers_erp: 'N. de camadas ERP',
    default: 'Padrão',
    order_for_all_suppliers_of_product: 'Considerar produtos de fornecimento alternativo',
    in_maintenance: 'Em manutenção',
    in_maintenance_message: 'Mensagem de Manutenção',
    or: 'ou',
    start_date: 'Data inicial',
    final_date: 'Data final',
    block: 'Bloquear',
    unblock: 'Desbloquear',
    download_xlsx_of_removed_items: 'Baixar itens removidos ou bloqueados',
    block_justification: 'Justificativa',
    save_block_status: 'Manter bloqueio para pedidos futuros',
    export_list: 'Exporta Lista',
    delete_all_items: 'Remover todos os itens',
    confirm_delete_all_items:
      'Todos os itens serão marcados como removidos do pedido' +
      ' e assim poderá voltar no pedido apenas os itens desejados.',
    auto_close_order: 'Fechamento automático de pedido',
    mirror_stores: 'Lojas espelho',
    mirror_store: 'Loja espelho',
    clear_ignored_pending_orders: 'Limpar pedidos pendentes removidos',
    apply_sistematics: 'Aplicar Sistemática',
    contract_priority_deadline: 'Priorizar prazo de entrega do contrato',
    change_all_items: 'Alterar todos os itens',
    just_this_order: 'Apenas para este pedido',
    return: 'Voltar',
    coverage_of_product_is: 'cobertura do produto é %{days}.',
    coverage_for_store: 'Cobertura de loja',
    consider_deadline_on_product: 'no produto esta para %{option}.',
    consider: 'considerar',
    not_consider: 'não considerar',
    empty: 'vazio',
    show_lasts_trade_in: 'Exibe últimas entradas',
    show_doi_changes: 'Exibe alterações do pedido do comprador restrito',
    filter: 'Filtro',
    base_quantity_is: 'Quantidade base é',
    delivered_code: 'Entregador',
    accept: 'Acatar',
    percent_of_purchase: '% de compra',
    buy_store: 'Loja de compra',
    distributed: 'Distribuido',
    centralized: 'Centralizado',
    central_store: 'Loja Central',
    requester_name: 'Solicitante',
    final_stock: 'Estoque',
    force_accept: 'Força aderir',
    true: 'Sim',
    false: 'Não',
    error: 'Erro',
    inform_error: 'Existem produtos com erros no pedido',
    confirm_close_with_error: 'Existem produtos com erros no pedido, deseja fechar mesmo assim?',
    errors: 'Erros',
    shipping_times: 'Prazo de entrega de fornecedor',
    activity_calendars: 'Calendário de Atividades',
    assign_products: 'Associar produto a loja',
    assigned_at: 'Entrou no cadastro em',
    unassigned_at: 'Saiu do cadastro em',
    supply_policies: 'Políticas de Abastecimento',
    status_historical: 'Histórico de alteração do Status',
    order_settings: 'Configurações de Pedidos',
    created_at: 'Criado',
    create: 'Cadastrar',
    updated_at: 'Última atualização',
    elapsed_time: 'Tempo de Processamento',
    processing: 'Processando',
    refresh_in_sec: 'atualiza a cada %{time}s',
    days_for_payment: 'Dias para pagamento',
    expiration_date: 'Data de entrega',
    order_delivery_forecast: 'Previsão de Entrega do Pedido',
    order_code: 'Código do pedido',
    order_codes_erp: 'Códigos de pedidos do erp',
    individual_change: 'Alteração individual',
    changes: 'Alterações',
    changed_by: 'Alterado por',
    next_visit: 'Próxima visita',
    extra_data: 'Dados extras',
    coverage_days_by_class: 'Dias de cobertura por classe',
    set_by_class: 'Definir por classe',
    implantation: 'Em Implantação',
    back_to_simple_mode: 'Voltar para simples',
    change_supplier: 'Trocar fornecedor',
    update_order_in_purchase_unit: 'Atualizar unidade de compra',
    maximum_coverage_day: 'Máximo de dias de cobertura',
    change_coverage: 'Alterar cobertura',
    forecast_stock_less_than: 'Estoque previsto menor que',
    change_front_line_capacities: 'Alterar capacidade da exposição',
    change_product_rounding: 'Alterar arredondamento do produto',
    disable_product_rounding: 'Desativar arredondamento do produto',
    enable_product_rounding: 'Ativar arredondamento do produto',
    change_seasonal_product: 'Produto Sazonal',
    reorder_without_pending_orders: 'Regerar pedido sem pedido pendente',
    change_systematic: 'Alterar sistemática',
    change_deadline: 'Alterar prazo de entrega',
    change_pallet_info: 'Alterar informações do palete',
    change_days_to_use_in_avg: 'Dias à considerar',
    change_combat_product: 'Produto de combate',
    combat_product_in_promotion: 'Produtos em combate não consideram promoções',
    type_combat_product: 'Tipo de Produto de Combate',
    consider_past_promotions: 'Promoções Anteriores',
    consider_constantly_on_sale: 'Promoção Constante',
    consider_past_promotions_only_with_stock_or_sale: 'Promoções Anteriores Com Venda ou Estoque',
    rational_amount_apportionment: 'Rateio racional de compra',
    centralized_purchase: 'Compra centralizada',
    change_block_items: 'Bloquear itens',
    unblock_items: 'Desbloquear itens',
    not_buy: 'VERMELHO - NÃO COMPRAR',
    buy: 'VERDE - Comprar',
    irregular_behavior: 'AZUL - Comportamento irregular',
    sudden_sale: 'LARANJA - Aumento de venda brusca',
    review: 'AMARELO - Revisar',
    store_dependencies: 'Dependências da loja',
    main_store: 'Loja principal',
    dependent_stores: 'Lojas dependentes',
    processed: 'Processado',
    block_for_purchase: 'Bloqueado para compra',
    product_transferred_another_supplier: 'Transferido de outro fornecedor',
    product_transferred_for_supplier: 'Transferido para outro fornecedor',
    show_supply_requests: 'Solicitação de reabastecimento',
    show_delivery_info: 'Exibir informações da entrega do pedido',
    transfer: 'Transferência',
    bound: 'Vincular',
    unbound: 'Desvincular',
    bound_status: 'Status Vinculado',
    supply_request_partially_bound: 'Parcialmente Vinculado',
    supply_request_multiple_statuses: 'Múltiplos Status',
    delete_request: 'Apagar Solicitação',
    supply_request_unbound: 'Não vinculado',
    supply_request_bound_to_this: 'Vinculado a este',
    supply_request_bound_to_other: 'Vinculado a outro',
    supply_request_unbounded_from_this: 'Desvinculado deste',
    supply_request_pending: 'Pendente',
    supply_request_accepting: 'Em Aceitação',
    supply_request_need_to_bound: 'Aguardando vinculação',
    supply_request_approved: 'Aprovado',
    approved: 'Aprovado',
    awaiting: 'Aguardando',
    rejected: 'Reprovado',
    reject: 'Reprovar',
    approve: 'Aprovar',
    release_requests: 'Solicitações de aprovação',
    message: 'Mensagem',
    see_order: 'Ver pedido',
    supply_request_deleted: 'Apagado',
    data_request: 'Data da Solicitação',
    time_request: 'Hora da Solicitação',
    update_time_request: 'Hora da Atualização',
    allow_weekend: 'Permitir Final de Semana',
    file: 'Arquivo',
    batch_import: 'Importação em lote',
    batch_importing: 'Importando em lote',
    hint_to_batch_import_file: 'Selecione o arquivo do EXCEL baixado que foi feito os ajustes nas quantidades',
    line: 'Linha',
    store_stock: 'Estoque de produto',
    rack_stock: 'Estoque de gôndola',
    shipping_times: 'Prazo de entrega',
    forced_to_use: 'Forçado a utilizar',
    readjust_order: 'Reajuste do pedido',
    readjust_order_direction: 'Mínimo/Máximo',
    readjust_order_value: 'Valor',
    product_informations: 'Informações do produto',
    round_order_unit_layer: 'Camada',
    just_this_order_days_to_use_in_avg: 'Apenas para este pedido',
    clear_all_days_to_use_in_avg: 'Alterar para todos os itens',
    days_to_use_in_avg: 'Quantidade de dias',
    start_date_to_use_in_avg: 'Data inicial',
    final_date_to_use_in_avg: 'Data final',
    systematic_for_product: 'Sistemática por produto',
    systematic_for_supplier: 'Sistemática por fornecedor',
    secondary_delivered: 'Centro de distribuição informado',
    default_distribution_center: 'Centro de distribuição padrão',
    rounding_for_product: 'Arredondamento por produto',
    days_to_consider: 'Dias à considerar',
    cockpit: 'Cockpit',
    users: 'Usuários',
    user: 'usuário',
    faq: 'FAQ',
    identification: 'Identificação',
    transform_schedule: 'Habilitar fechamento',
    enable_close: 'Habilitar agenda para fechamento de compra',
    send_solicitation: 'Enviar solicitação',
    simulation_count: 'Agendas de simulação',
    orders_count: 'Total de agendas',
    orders_count_default: 'Agendas normais',
    closed_orders_count: 'Agendas fechadas',
    closed_orders_percent: 'Agendas fechadas (%)',
    ddv_calculation_type: 'Cálculo de DDV',
    advanced_supplier: 'Fornecedor avançado',
    centralized_store_without_product: 'Loja centralizada sem produto',
    systematic_not_distribution_center: 'Sistemática não pode ser centro de distribuição',
  },
  activity_calendar: {
    edit_activity_calendar: 'Editar Calendário de Atividades',
    date: 'Data',
    without_sale: 'Sem Venda',
    without_order: 'Sem Pedido',
    without_delivery: 'Sem Entrega',
    errors: {
      date: 'Data é obrigatória',
      activity_params: 'É necessário informar ao menos uma atividade',
      error: 'Erro ao salvar calendário de atividades',
    },
  },
  calendar: {
    date_format: {
      default: 'd/m/Y',
    },
    sunday: 'Domingo',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
    abbr_day_names: {
      sunday: 'Dom',
      monday: 'Seg',
      tuesday: 'Ter',
      wednesday: 'Qua',
      thursday: 'Qui',
      friday: 'Sex',
      saturday: 'Sáb',
    },
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'November',
    december: 'Dezembro',
    abbr_month: {
      jan: 'Jan',
      feb: 'Fev',
      mar: 'Mar',
      apr: 'Abr',
      may: 'Mai',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Set',
      oct: 'Out',
      nov: 'Nov',
      dec: 'Dez',
    },
  },
  calculation_method: {
    avg_adjusted_sales: 'Venda ajustada',
    prediction: 'Previsão',
  },
  periodicities: {
    unique_schedule: 'Agenda única',
    in_days: 'Em dias',
    weekly: 'Semanal',
    biweekly: 'Quinzenal',
    monthly: 'Mensal',
    bimonthly: 'Bimestral',
    quarter: 'Trimestral',
  },
  charts: {
    activity: 'Atividade do Mix',
    activity_days: 'Alerta de Estoque Virtual',
    days_without_sales: 'Dias sem vendas',
    ruptures: 'Ruptura comercial',
    negative: 'Estoque Negativo',
    last_30: 'Estoque Excedente',
    virtual_stock_alerts: 'Alerta de Estoque Virtual',
    abc_shares: 'Participação do Mix',
    ruptures_alert: 'Alerta de Rupturas',
    ruptures_logistics: 'Ruptura Logistica',
    abc_curve: 'Curva ABC',
    negative_margin: 'Margem Negativa',
    top_sales: 'Top Vendas',
    cost_variation: 'Variação de Custo',
    sale_variation: 'Variação de Venda',
    cost_variation_abbreviation: 'Var. custo',
    sale_variation_abbreviation: 'Var. venda',
    top_ruptures: 'Top Rupturas',
    top_ruptures_in_value: 'Top Rupturas em Valor',
    top_ruptures_in_quantity: 'Top Rupturas em Quantidade',
    top_ruptures_in_incidence: 'Top Rupturas em Incidência',
    top_breaks_in_value: 'Top Quebras em Valor',
    top_breaks_in_quantity: 'Top Quebras em Quantidade',
    top_breaks_in_incidence: 'Top Quebras em Incidência',
    top_breaks: 'Top Quebras',
    virtual_alerts: 'Ruptura Operacional',
    in_value: 'em Valor',
    recidivism: 'em Inicidência',
    lost_products: 'em Quantidade',
    control_panel: 'Painel de Controle',
    indicators: 'Indicadores',
    performance: 'Performance',
    ordering_suggestions: 'Sugestões de pedidos',
    transversal_view: 'Visão Transversal',
    continuous_improvement_action: 'Ação de Melhoria Contínua',
    listing: 'Listagem',
    product_history: 'Histórico do Produto',
    virtual_stock_alerts_classification: {
      '1- HIGHEST': 'Muito alto',
      '2- HIGH': 'Alto',
      '3- AVERAGE': 'Média',
      '4- LOW': 'Baixo',
    },
  },
  charts_info: {
    activity: {
      in_line: 'linha',
      for_purchase: 'compra',
      without_stock: 's/estoque',
      without_order: 's/pedido',
      inactive: 'inativo',
      without_schedule: 's/agenda',
      without_sale: 's/venda',
      all: 'tudo',
    },
    activity_days: {
      d7: 'd7',
      d30: 'd30',
      d60: 'd60',
      d90: 'd90',
      'd+': 'd+',
    },
    days_without_sales: {
      d7: 'd7',
      d30: 'd30',
      d60: 'd60',
      d90: 'd90',
      'd+': 'd+',
    },
    ruptures_alert: {
      all: 'Tudo',
      supplied_by_cd: 'Abastecido pelo CD',
    },
  },
  filters: {
    nothing_found: 'Nenhum registro encontrado',
    select_one_bg: 'Selecione um grupo econômico',
    type_to_search: 'Digite para procurar',
    all_products: 'Todos os produtos',
    only_launch: 'Só lançamento',
    no_launch: 'Sem lançamento',
    confirm_next_visit: 'Confirmar próxima visita',
    open_schedule: 'Abrir agenda',
    edit_schedule: 'Editar agenda',
    synchronize_erp: 'Sincronizar ERP',
    click_for_open: 'clique para abrir',
    only_promotion: 'Só Promoção',
    no_promotion: 'Sem Promoção',
  },
  modal: {
    title_expand_line: 'Abrir resumo mensal do produto',
    title_collapse_line: 'Fechar resumo mensal do produto',
    product_with_invalid_data: 'Produto com dados inválidos',
    delivery_item_erp: 'Entrega do item no ERP',
    last_item_delivered_erp: 'Último item entregue no ERP em',
  },
  validations: {
    required: 'Campo obrigatório',
  },
  alerts: {
    dont_have_data_toggle_property: 'Não possui propriedade data-toggle',
    synchronize_database:
      'Deseja sincronizar os dados dos produtos desse pedidos com' + ' o ERP? <i>(o pedido será regerado)</i>',
    loading_charts: 'Erro carregando dados do gráfico, tente novamente',
    changed_coefficients: 'Coeficientes foram alterados, deseja prosseguir? As alterações serão perdidas',
    not_records: 'Não existem registros',
    select_an_action: 'Selecione uma opção',
    select_one_or_more_options: 'Selecione uma ou mais opções',
    product_not_found: 'Nenhum produto encontrado',
    searching_for: 'Procurando por',
    select_economic_group: 'Selecione um grupo econômico',
    type_to_search: 'Digite para procurar',
    not_found: 'Nenhum registro encontrado',
    not_record_for: 'Nenhum resultado para:',
    supplier_not_found: 'Nenhum fornecedor encontrado',
    store_not_found: 'Nenhuma loja encontrada',
    all_stores_selected: 'Caso nenhuma loja seja selecionada todas as lojas serão alteradas.',
    regional_not_found: 'Nenhuma regional encontrada',
    select_stores: 'Selecione as lojas',
    select_purchase_type: 'Selecione o tipo de Unidade de Compra',
    code_supllier_name: 'Código ou nome do fornecedor...',
    updating_information: 'Atualizando informações',
    calculating_orders: 'Calculando pedidos',
    getting_information: 'Obtendo informações',
    inform_support: 'Informe ao suporte:',
    connection_error: 'Erro de conexão',
    processing_error: 'Ocorreu um erro no processamento',
    generating_order: 'Gerando pedidos',
    unsupported_browser: 'Navegador não compativel',
    no_result_for_the_period: 'Sem resultado para o período',
    send_email_xls: 'Estamos processando sua solicitação e em breve  enviaremos o arquivo XLS para o seu email: ',
    lost_connection_try_again: 'Perda de conexão ou erro interno. Tente novamente mais tarde.',
    price_table_not_found: 'Não existe tabela de preço para esse produto.',
    promotions_table_not_found: 'Não existe tabela de promoção para esse produto.',
    confirm_delete_schedule: 'Deseja realmente apagar agenda?',
    confirm_save_duplicate_schedule: 'Deseja regerar ou duplicar a agenda?',
    product_similarities: 'Produtos com Similaridade',
    first_warn_before_delete:
      'Caso a agenda já esteja com o' +
      ' pedido fechado o mesmo continuará fechado e será removido apenas a próxima agenda.',
    second_warn_before_delete:
      'Se' +
      ' a agenda não tiver pedido fechado, o pedido será removido, a agenda atual e os seguintes também serão removidos.',
    ask_to_reopen_order: 'Deseja realmente reabrir este pedido?',
    reopen_order_warning:
      'Se o pedido já estiver integrado com o ERP,' + ' será preciso cancelá-lo para que não haja duplicidades.',
    rounding_factor_required: 'Fator de multiplicação ou Fator de multiplicação de venda é obrigatório',
    delete_request: 'Deseja realmente apagar a solicitação?',
    coverage_limit: 'Seu limite de cobertura é',
    no_results: 'Sem resultados',
    saving_schedule: 'Salvando agenda',
    creating_schedule: 'Criando agenda',
  },
  pluralize: {
    purchaseunit: '{value} {value, plural, one {un.} =0 {un.} other {un.} }',
    unit: '{value} {value, plural, one {un.} =0 {un.} other {un.} }',
    days: '{value} {value, plural, one {dia} =0 {dia} other {dias} }',
    value: '{value, number, money}',
  },
  tabs: {
    averageStock: 'Estoque médio',
    consumption: 'Consumo',
    tradeIn: 'Compras',
    actualSales: 'Vendas',
    break: 'Quebras',
    ruptures: 'Rupturas',
    inventoryAdjustment: 'Inventário',
  },
  // pode ser encontrado no node_modules/momentjs/locale/pt-br
  momentjs: {
    months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
    monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
    weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split('_'),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
    weekdaysMin: 'do_2ª_3ª_4ª_5ª_6ª_sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'HH:mm',
      LTS: 'HH:mm:ss',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY [às] HH:mm',
      LLLL: 'dddd, D [de] MMMM [de] YYYY [às] HH:mm',
    },
    calendar: {
      sameDay: '[Hoje às] LT',
      nextDay: '[Amanhã às] LT',
      nextWeek: 'dddd [às] LT',
      lastDay: '[Ontem às] LT',
      lastWeek: function () {
        return this.day() === 0 || this.day() === 6
          ? '[Último] dddd [às] LT' // Saturday + Sunday
          : '[Última] dddd [às] LT'; // Monday - Friday
      },
      sameElse: 'L',
      month: 'Mês',
      day: 'Dia',
      week: 'Semana',
      schedule: 'Agenda',
    },
    relativeTime: {
      future: 'em %s',
      past: 'há %s',
      s: 'poucos segundos',
      ss: '%d segundos',
      m: 'um minuto',
      mm: '%d minutos',
      h: 'uma hora',
      hh: '%d horas',
      d: 'um dia',
      dd: '%d dias',
      M: 'um mês',
      MM: '%d meses',
      y: 'um ano',
      yy: '%d anos',
    },
    dayOfMonthOrdinalParse: /\d{1,2}º/,
    ordinal: '%dº',
  },
  number: {
    money: {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    rounded: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    symbols: {
      decimal: ',',
      thousand: '.',
    },
  },
  ascDescSelector: {
    up: 'Ordem Crescente',
    down: 'Ordem Decrescente',
    default: 'Cancelar Ordenação',
  },
  product_supply_type: {
    supplier: 'Entregue pelo Fornecedor',
    supplier_abbr: 'Entreg. Forn.',
    supplier_for_print: 'FORNECEDOR',
    cross_docking: 'Entregue pelo CD - Cross-Docking',
    cross_docking_abbr: 'Cross-Docking',
    cross_docking_for_print: 'CROSS-DOCKING',
    distribution_center: 'Entregue pelo CD',
    distribution_center_abbr: 'Entreg. CD',
    distribution_center_for_print: 'CD',
  },
  movement_type_actions: {
    ignore: 'Ignorar',
    trade_out: 'Venda',
    trade_in: 'Recebimento',
    devolution: 'Devolução',
    inventory: 'Inventário',
    break: 'Quebra',
    break_by_validity: 'Quebra por validade',
    consumption: 'Consumo',
    atypical_sale: 'Venda Atípica',
  },
  tax_informations: {
    purchase_price_simulator: 'Simulação de Preço de Compra',
    supplier_code: 'Código do Fornecedor',
    supplier_name: 'Nome do Fornecedor',
    buying_price: 'Preço de Compra',
    in_tax_rate: 'Carga tributária de entrada',
    net_cost: 'Custo Líquido',
    sell_in: 'Verba',
    managing_fee: 'Custo gerencial',
    margin: 'Margem',
    out_tax_rate: 'Carga tributária de saída',
    sell_price_with_margin: 'Preço de Venda com Margem',
    sell_price_without_margin: 'Preço de Venda sem Margem',
    reference_price: 'Preço de Referência',
    foreseen_margin: 'Margem Prevista',
    buying_ideal_cost: 'Custo Ideal de Compra',
    price_ideal_cost: 'Preço Ideal de Compra',
    suggested_sell_in: 'Valor de verba sugerido',
    profit_margin: 'Margem de Lucro',
    sell_price: 'Preço de Venda',
    destination_location: 'Destino',
    origin_location: 'Origem',
    contract_negotiation_contract_code: 'Código do Contrato',
    contract_negotiation_contract_description: 'Descrição do Contrato',
    sku_total: 'Total de SKUs',
    total_purchase_value: 'Valor de Compra',
    in_tax_total: 'Carga Tributária de Entrada',
    net_cost_total: 'Custo Líquido',
    total_sell_in: 'Verba',
    managing_fee_total: 'Custo Gerencial',
    margin_value_total: 'Margem',
    margin_value_total_with_sell_in: 'Margem com Sell In',
    out_tax_rate_total: 'Carga Tributária de Saída',
    total_value_sell_price: 'Valor de Venda',
  },
  navbar: {
    business_groups: 'Grupos Econômicos',
    batch_imports: 'Importações',
    product_units: 'Unidades de Produto',
    holiday_calendars: 'Calendário de feriados',
    configurations: 'Configurações',
    product_similarities: 'Produtos com Similaridade',
    cellphone_contact: ' (19) 99386-7790 ',
    account: 'Conta',
    signout: 'Sair',
  },
  holiday_calendar: {
    name: 'Nome',
    kind: 'Tipo do Feriado',
    fixed_date: 'Data Fixa',
    uf: 'UF',
    city: 'Cidade',
    date: 'Data',
    kinds: {
      national: 'Nacional',
      state: 'Estadual',
      city: 'Municipal',
    },
  },
  forms: {
    rules: {
      required: 'Campo obrigatório',
      email: 'E-mail inválido',
      invalid_confirm_password: 'Senha e confirmação de senha não conferem',
      market_structure_required: 'Estrutura Mercadológica é obrigatório',
      supplier_required: 'Fornecedor é obrigatório',
    },
  },
  roles: {
    administrator: 'Administrador',
    monitor: 'Monitor',
    manager: 'Gestor',
    regional: 'Regional',
    store: 'Loja',
    salesman: 'Vendedor',
    consultant: 'Consultor',
    buyer: 'Comprador',
    supplier: 'Fornecedor',
    restricted_buyer: 'Comprador restrito',
    admin_client: 'Admin cliente',
    product_family: 'Familia',
  },
  batch_import: {
    file_data: 'Arquivo',
    filename: 'Nome do arquivo',
    user_id: 'Usuário',
    created_at: 'Criado em',
    origin: 'Tipo',
    origins: {
      draft_orders: 'Pedido',
      delivery_times: 'Prazo de entrega',
    },
    statuses: {
      error: 'Erro',
      success: 'Finalizado',
    },
  },
  api_log: {
    id: 'ID',
    url: 'URL',
    http_status: 'HTTP status',
    created_at: 'Criado em',
    finished_at: 'Finalizado em',
    header: 'Header',
    data: 'Adicionais',
    body: 'Corpo',
    response: 'Resposta',
    method: 'Método',
  },
  request_log: {
    user: 'Usuário',
    controller_name: 'Controlador',
    action_name: 'Ação',
    method: 'Método',
    params: 'Parametros',
    envs: 'ENVs',
    created_at: 'Criado em',
    id: 'Código',
  },
  job_managers: {
    status: 'Status',
    created_at: 'Criado em',
    finished_at: 'Finalizado em',
    origin: 'Origem',
    parent: 'Antecessor',
    progress: 'Progresso',
    status_values: {
      processing: 'Processando',
      finished: 'Finalizado',
      with_error: 'Com erro',
    },
  },
  addons: {
    exposure_type: 'Tipo de exposição',
    business_inteligence_abbreviation: 'BI',
    budgetary_plan: 'Plano Orçamentário',
    buyers: 'Compradores',
    documentation: 'Documentação',
    quizzer: 'Quizzer',
    workflow: 'Workflow',
    manager_panel: 'Painel Gestor',
    analytics: 'Analytics',
    pricing: 'Pricing',
    suppliers: 'Fornecedores',
    vision: 'Visão',
    tabloid: 'Tablóide',
    supply: 'Abastecimento',
    balancing: 'Balanceamento',
    promotions: 'Promoções',
    schedules: 'Agendas',
    api: 'API',
    exposure: 'Exposição',
    rounding: 'Arredondamento',
    pallets: 'Paletes',
    addons: 'addons',
    quotation: 'Cotação',
    resupplier: 'Ressuprimento',
    support: 'softwares de apoio',
  },
  combat_product: {
    error: {
      message: 'Tipo de Produto de Combate é obrigatório para produtos marcados como Produto de Combate',
    },
  },
  promotion_product: {
    error: {
      type_message: 'Tipo de Produto de Promoção é obrigatório para produtos marcados como Produto de Promoção',
      value_message: 'Valor do Produto de Promoção é obrigatório para produtos marcados como Produto de Promoção',
    },
  },
  mirror_stores_form: {
    origin_store: 'Loja origem',
    destination_store: 'Loja destino',
    initial_date: 'Data inicial',
    final_date: 'Data final',
    multiplication_factor: 'Fator de multiplicação',
    errors: {
      initial_date: 'Data inicial é obrigatória',
      final_date: 'Data final é obrigatória',
      multiplication_factor: 'Fator de multiplicação é obrigatório',
      origin_store: 'Loja origem é obrigatória',
      destination_store: 'Loja destino é obrigatória',
    },
  },
  similarities: {
    error: {
      empty_products: 'Não é possível salvar uma similaridade sem produtos',
    },
  },
  classification_configs: {
    error: {
      error: 'Erro',
      sum_error: 'A soma das participações não pode ser diferente de 100%',
    },
  },
  stores_form: {
    required: {
      regional: 'Regional é obrigatório',
      identification: 'Identificação é obrigatório',
      store_code: 'Código da loja é obrigatório',
      trade_name: 'Nome fantasia é obrigatório',
      address: 'Endereço é obrigatório',
      address_number: 'Número é obrigatório',
      zip_code: 'CEP é obrigatório',
      country: 'País é obrigatório',
      state: 'Estado é obrigatório',
      city: 'Cidade é obrigatório',
      room_size: 'Tamanho do salão é obrigatório',
      checkout_count: 'Número de checkouts é obrigatório',
      manager_name: 'Nome do gerente é obrigatório',
      manager_email: 'E-mail do gerente é obrigatório',
      telephone: 'Telefone é obrigatório',
      mobile_phone: 'Celular é obrigatório',
      negotiated_value: 'Valor negociado é obrigatório',
      CNPJ: 'CNPJ é obrigatório',
    },
  },
  palletsError: {
    tooltip: 'Erro na configuração de paletes',
    title: 'Itens com erro',
    not_informed: 'Não informado ou zero',
  },
  errors: {
    regionals: {
      delete: 'Erro ao apagar regional',
    },
    product_units: {
      delete: 'Erro ao apagar unidade de produto',
    },
    create: 'Erro ao criar!',
    update: 'Erro ao editar!',
  },
  success: {
    create: 'Criado com sucesso!',
    update: 'Atualizado com sucesso!',
  },
};
