const ptGeneral = require('../intl/pt_br/general');
const ptTootips = require('../intl/pt_br/tooltip');

const esGeneral = require('../intl/es/general');
const esTootips = require('../intl/es/tooltip');

// tras a traducao do backend (app/views/cockpit/index.html.slim)
const lists = {
  table_lists: window.i18n_lists || {},
  lists: {}
};

// ajusta traducoes das colunas e order_by
if (!Object.keys(lists.lists).length) {
  Object.keys(lists.table_lists).forEach(function(listKey) {
    let keys = Object.keys(lists.table_lists[listKey]);

    keys.forEach(function(key) {
      lists.lists[key] = lists.lists[key] || lists.table_lists[listKey][key];
    });

    const hiddenColumns = [
      'actions',
      'open_details',
      'complete',
      'changed_by_name',
      'supply_request_not_closed',
      'total_supply_request_bounded'
    ];
    if (listKey.indexOf('_order_by') === -1) {
      keys = keys.filter(item => !hiddenColumns.includes(item));
      keys = keys.sort(function(a, b) {
        a = lists.table_lists[listKey][a];
        b = lists.table_lists[listKey][b];

        return ('' + a).localeCompare(b);
      });
      lists.table_lists[listKey + '_order_by'] = lists.table_lists[listKey + '_order_by'] || keys;
    }
  });
}

const allLocales = {
  'pt-BR': Object.assign(ptGeneral, ptTootips, lists),
  es: Object.assign(esGeneral, esTootips, lists)
};

let definedLocale;

const getLocale = function() {
  if (!definedLocale) {
    if (window.location !== window.parent.location) {
      definedLocale = window.parent.i18nLocale || window.parent.document.querySelector('html').lang;
    } else {
      definedLocale = window.i18nLocale || document.querySelector('html').lang;
    }
  }
  return definedLocale;
};

module.exports = (function() {
  return {
    locale: getLocale(),
    t: function(message, values) {
      const i18nLocale = getLocale();
      const currentLocale = allLocales[i18nLocale];
      const arrayMessage = message.split('.');
      let intl = '';
      values = values || {};

      arrayMessage.forEach(function(key, index) {
        let withoutKey = false;
        try {
          if (index === 0) {
            if (Object.keys(currentLocale || {}).indexOf(key) === -1) withoutKey = true;
            intl = currentLocale[key];
          } else {
            if (Object.keys(intl || {}).indexOf(key) === -1) withoutKey = true;
            intl = intl[key];
          }
          if (withoutKey) throw new Error('without key');
        } catch (error) {
          if (values.default === undefined) {
            console.info('key_not_found', i18nLocale + '.' + message);
          }
        }
      });

      if (intl === undefined) {
        if (values.default === undefined) return message;
        else intl = '';
      }

      Object.keys(values).forEach(function(key) {
        intl = ('' + intl).replace('%{' + key + '}', values[key]);
      });

      if ((!intl || intl === '') && values.default) intl = values.default;

      return intl;
    }
  };
}());
