// adicionar também em config\locales\models\table_lists.pt-BR.yml
module.exports = {
  currency: {
    abreviation: 'ARG',
    symbol: '$',
    decimals: 2,
  },
  general: {
    multiple_cargo_deliveries: 'Múltiples Cargas de Entrega',
    coverage_by_curve: 'Cobertura por Curva',
    coverage_by_class: 'Cobertura por Clase',
    rule: 'Regla',
    store_cluster: 'Grupo de Tienda',
    adding_products: 'Añadiendo productos',
    enable_mirrored_simulation_schedules: 'Habilitar simulación de agendas espejo',
    add_products: 'Agregar productos',
    added: 'Agregado',
    not_added: 'No agregado',
    type: 'Tipo',
    add: 'Agregar',
    stores_block_for_purchase: 'Bloqueados para compra',
    schedule_name: 'Agenda',
    all: 'Todos',
    fem_all: 'Todas',
    actions: 'Acciones',
    actual_stock: 'Stock Actual',
    actual_month: 'Mes actual',
    actual_year: 'Año actual',
    adjusted_sale: 'Venta normalizada',
    add_tolerance: 'Agregar tolerancia',
    adjusted_sale_abrev: 'V. norm',
    rounding_percentages: 'Porcentaje para redondeo de pedidos',
    safety_stock_days: 'Días de stock de seguridad',
    adjusts: 'Ajustes de Inventario',
    address: 'Dirección',
    address_number: 'Número',
    zip_code: 'ZIP CP',
    country: 'Pais',
    room_size: 'Tamaño del salon',
    manager_name: 'Nombre del gerente',
    manager_email: 'Email del gerente',
    activate: 'Activar',
    inactivate: 'Inactivar',
    already_bring_dc: 'Lleva siempre el centro de distribución',
    average_stock: 'Stock medio',
    average_unit_cost: 'Costo Promedio',
    apply_in_generate_orders: 'Aplicar en la generación de pedidos',
    maximum_coverage_days_by_actual_sales: 'Días de cobertura máximos por ventas actuais',
    maximum_coverage_days_by_actual_sale: 'Días de cobertura máximos por ventas actuais',
    avg_actual_sales_90: 'Media de ventas reales últimos 90 dias',
    avg_adjusted_sales: 'Media de ventas ajustadas',
    avg_actual_sales_90_purchase_unit: 'Media de ventas diarias  (unid. compra)',
    avg_adjusted_sales_purchase_unit: 'Media de ventas ajustadas (unid. compra)',
    value_type: 'Tipo de valor',
    list_all: 'Listar Todos',
    create_cargo_multiple_pallets: 'Formar carga múltiples paletas',
    cargo_round_percentage: 'Porcentaje de redondeo de carga',
    default_pallet_rounding: 'Redondeo de paletas por defecto',
    average: 'Media',
    branch_line: 'Extensión',
    breaks: 'Mermas',
    billings: 'Facturacion',
    billing_abbreviation: 'Fact',
    removing_pending_orders: 'Eliminación de pedidos pendientes',
    business_description: 'Descripción comercial',
    active_for_purchase: 'Activo para la compra',
    active_for_sale: 'Activo para la venta',
    active_on_mix: 'Activo en el surtido',
    updating_systematic: 'Actualización de la Sistemática',
    business_groups: 'Grupo Economico',
    buyer: 'Comprador',
    name: 'Nombre',
    quotation: 'Precio',
    came_in_line: 'Entro en linea',
    cancel: 'Cancelar',
    stores_view: 'Vista por Tienda',
    supplier_view: 'Vista por Proveedor',
    changed: 'Modificado',
    update: 'Actualizar',
    changed_coverage: 'Modificar cobertura',
    charts: 'Gráficos',
    chart: 'Gráfico',
    class: 'Clase',
    code: 'Código',
    type_of_block: 'Tipo de bloqueo',
    classification: 'clasificacion',
    today: 'Hoy',
    clear: 'Limpiar',
    clear_selection: 'Limpiar seleccion',
    click_to_open: 'click para abrir',
    click_to_hide: 'Click para ocultar',
    click_to_show: 'Click para mostrar',
    process_following_day: 'Procesar el dia siguiente da importación',
    until_today: 'Procesar hasta hoy',
    process_data: 'Procesar Prevision',
    click_to_edit: 'click para editar',
    click_to_expand: 'Click para expandir',
    close: 'Cerrar',
    closed: 'Cerrado',
    closure: 'Cierre',
    close_chidren_products: 'Cerrar productos Hijos',
    column_adjusts: 'Ajuste de columnas',
    confirm: 'Confirmar',
    complete_order: 'Terminar pedido',
    complete_product: 'Terminar produto',
    consider_deadline: 'Considere el tiempo de entrega',
    consider_exposure: 'Considere la exposición',
    consider_derivatives: 'Considere los derivados',
    consider_child_products: 'Considero produtos filhos',
    consumption: 'Consumo',
    coverage: 'Cobertura',
    coverage_consider_delivery_day: 'Cobertura Considera Dia da entrega?',
    children_products: 'Productos Hijos',
    date: 'Fecha',
    day: 'Dia',
    days: 'Dias',
    combo_considering_coverage: 'Cobertura',
    daily_frequency: 'Frecuencia Diaria',
    discount: 'Descuento',
    create_cargo: 'Formar carga',
    simulate: 'Simular',
    week_frequency: 'Frecuencia Semanal',
    month_frequency: 'Frecuencia Mensual',
    daily_orders_allowed: 'Ver sugerencias de pedidos',
    data_ordered: 'Orden de los datos',
    default_list_configuration: 'Configuración de listado estándar',
    decreasing: 'decreciente',
    description: 'Descripción',
    deadline: 'Tiempo de entrega',
    secondary_deadline: 'Tiempo de entrega (informado)',
    secondary_full_capacity_pallet: 'Cant. Paletas',
    details: 'Detalles',
    ordering_suggestions: 'Sugerencia de pedidos',
    deliveries: 'Entregas',
    delivery_prediction_date: 'Fecha de Prevision de Entrega',
    delivery_date: 'Fecha de entrega',
    deliveries_and_devolutions: 'Entregas y Devoluciones',
    devolutions: 'Devoluciones',
    distributor: 'Distribuidor',
    download_csv: 'Bajar en CSV',
    download_xlsx: 'Bajar en XLSX',
    manipulation_time: 'Tiempo de manipulación',
    download_pdf: 'Bajar en PDF',
    download_in: 'Bajar en',
    duration: 'Duracion',
    erp_synchronize: 'Sincronizar ERP',
    ean: 'EAN',
    edit: 'Editar',
    entrance: 'Entrada',
    email: 'Email',
    estimated_quantity: 'Cantidad Estimada',
    expiration_date_br: 'Fecha de<br />caducidad',
    shipping_time_br: 'Plazo de entrega',
    export: 'Exportar',
    filters: 'Filtros',
    frequency: 'Frecuencia',
    first: 'Primero',
    filter_of: 'filtrado de',
    go_to: 'Ir a',
    greatness: 'Grandeza',
    growing: 'creciente',
    group: 'Grupo',
    department: 'Departamento',
    horary: 'Horario',
    hide_column: 'Ocultar columna',
    hidden_columns: 'Columnas ocultas',
    horizontal: 'Horizontal',
    records_in_total: 'registro(s) en el total',
    order_item_blocked: 'Bloquear/Desbloquear Item',
    active: 'Activo',
    inactive: 'Inactivo',
    initial: 'inicial',
    informed_sales_abrev: 'V. Inf',
    inventory: 'Austes Inventario',
    inventory_and_breaks: 'Ajustes Inventario y Mermas',
    inventory_turnover: 'Giro de Stock',
    keep_order_changes: 'Mantener los Cambios de Orden',
    zero_suggestion: 'Restablecer Productos de Suministro Alternativo',
    production_schedule: 'Producción',
    product_store_blocks: 'Cerraduras',
    last: 'Último',
    last_buy_unit_cost: 'Último Precio de Costo',
    last_entry_unit_cost: 'Último costo de entrada',
    last_sale_price: 'Último Precio de Venta',
    last_sale_purchase_br: 'Último precio de compra',
    last_month: 'Último mes',
    last_suggestion_date: 'Fecha de Última Sugerencia',
    last_week: 'Última semana',
    last_weekend: 'Último fin de semana',
    last_year: 'Año anterior',
    list: 'Listado',
    year_before_last: 'Año retrasado',
    launch: 'Lanzamientos',
    primary_sale: 'Lanzamiento',
    level: 'Nivel',
    linear_front: 'Exposición ERP',
    loading: 'Cargando...',
    market_structure: 'Estructura Merceológica',
    market_structures: 'Estructuras Merceológicas',
    rounding: 'Redondeo',
    maintenance: 'Esta pantalla está em manutenimiento.',
    margin: 'Margen',
    maximum: 'Máximo',
    minimum: 'Mínimo',
    minimum_stock: 'Stock Mínimo',
    minimum_order: 'Pedido Mínimo',
    maxima: 'Máxima',
    minima: 'Mínima',
    monitoring: 'Supervision',
    month: 'Mes',
    monthly_sale: 'Venta Mensual',
    mobile: 'Teléfono móvil',
    next: 'Próximo',
    new: 'Nuevo',
    news: 'Novedad',
    new_column_need_ordered: 'columna nueva, necesita ser ordenada',
    next_order: 'Próximo Pedido',
    no_prediction: 'Sin Prevision',
    negotiated_price: 'Precio negociado',
    change_negotiated_price: 'Modificar precio negociado',
    order_in_purchase_unit: 'Pedido (unid. compra)',
    change_order_in_purchase_unit: 'Cambiar Pedido Unid. Compra',
    trade_name: 'Nombre Comercial',
    implantation: 'En Implantación',
    of: 'de',
    of_billings: 'de facturacion',
    of_products: 'de los productos',
    truck: 'Camión',
    truck_load: 'Carga de camión',
    cross_dock_rounding: 'Redondeo Cross Dock',
    hide_costs: 'Ocultar costos',
    hide_indicators: 'Ocultar indicadores',
    hide_breaks: 'Ocultar mermas',
    order: 'Pedido',
    show_schedule_changes: 'Cambios de agenda',
    order_date: 'Fecha do Pedido',
    order_calendar: 'Calendario de Pedidos',
    observations: 'Observaciones',
    opening: 'Apertura',
    open_children_products: 'Abrir productos hijos',
    hide: 'Esconder',
    operational_ruptures: 'Faltantes Operativos',
    out_of_line: 'Salio de linea',
    original_transfer_schedule_next_visit: 'Fecha de la Agenda Original',
    parameters: 'Parametros',
    pay_day: 'Dias para el pago',
    parent_name: 'Producto Base',
    password: 'Contraseña',
    new_password: 'Nueva contraseña',
    password_confirmation: 'Confirmación de contraseña',
    new_password_confirmation: 'Nueva confirmación de contraseña',
    periodicity: 'Frecuencia',
    ddv: 'Días de Venta',
    ddv_with_buy: 'Días de Venta con Compra',
    pending_orders: 'Pedidos Pendientes',
    open_apply_systematics_modal: 'Abra el modal Aplicar sistemática',
    percent_sale: '% Venta',
    pending_order_sale_unit: 'Ped. Pendientex Unid. Venta',
    pending_order_purchase_unit: 'Ped. Pendientex Unid. Compra',
    sale_unit: 'Unidad de Venta',
    next_order_date: 'Data do Próx. Pedido',
    percent_sale_of_last_month: '% sobre la venta del mes anterior',
    percentage: 'Porcentaje',
    percentage_over_days: 'Porcentaje sobre los dias',
    manual_cargo: 'Entrenamiento de cargas manuales múltiples',
    seasonal: 'Estacionalidad',
    seasonal_products_chart: 'Gráfico de productos estacionales',
    boxes: 'Cajas',
    permit_app_mobile: 'Permite app mobile',
    position: 'Posicion',
    price_table: 'Tabla de Precios',
    promotions_table: 'Tabla de Promociones',
    mirrored_product_store: 'Producto reflejado en la tienda',
    promotion_value: 'Valor de promoción',
    promotion_created_by_erp: 'Promoción creada por ERP',
    price_name: 'Nombre del precio',
    initial_date: 'Fecha de inicio',
    expiration_type: 'Tipo de vencimiento',
    promotion_code: 'Código de promoción',
    prices_and_costs: 'Precios y Costos',
    prediction: 'Prevision',
    previous: 'Anterior',
    projection: 'Projeccion',
    product: 'Producto',
    product_quantity_abreviation: 'Cant. de Productos',
    buyers: 'Compradores',
    percentage_required: 'Porcentaje requerido',
    market_structure_required: 'Estructura Merceológica requerida',
    product_code_abreviation: 'Cód. Produto',
    proccessing: 'Procesando...',
    promotion: 'Promocion',
    promotion_name: 'Nombre de la promoción',
    promotion_sale: 'Venta en promocion',
    promotion_days: 'Dias en promocion',
    product_history: 'Histórico de Producto',
    purchase_unit: 'Unidad de Compra',
    purchase_unit_br: 'Unidad<br />compra',
    purchase_unit_abreviation: 'Un Compra',
    quantity_days: 'Cantidad de dias',
    quantity_sku_abbreviation: 'Cant Skus',
    quantity_in_purchase_unit: 'Cantidad en Embalaje de Compra',
    quantity_in_purchase_unit_br: 'Cant en embalaje<br />de compra',
    quantity_last_suggestion: 'Cantidad de la Última Sugerencia',
    quantity_in_percentage: 'Cantidad (em %)',
    quantity: 'Cantidad',
    quantity_package_abreviation: 'Cant embalaje',
    rate_rupture: 'Tasa de Faltante Comercial',
    rate_operational_rupture: 'Indice de Faltante Operativo',
    real_sale: 'Venta Real',
    real_sales_abrev: 'V. real',
    regional: 'Regional',
    record_pluralize: 'registro(s)',
    records: 'Registros',
    release_request_approver: 'Aprobación de solicitud de liberación',
    functional_record: 'Registro Funcional',
    regenerating_order: 'Regerando o pedido',
    regenerating_by_data_change: 'Regeneración por actualización de datos',
    rupture: 'Faltante',
    ruptures: 'Faltantes Comercial',
    rupture_rate: 'Índice de Faltante',
    retail_unit_price: 'precio al por menor',
    role_type: 'Perfil',
    suspended: 'Suspendido',
    sale: 'Venta',
    sale_prediction: 'Prevision de venta',
    sales: 'Ventas',
    sales_prediction: 'Ventas/Prevision',
    sales_unit: 'Unidad de Venta',
    sale_in_quantity: 'ventas en cantidad',
    sales_unit_abreviation: 'Un Venta',
    save: 'Guardar',
    regenerate_schedule: 'Recalcular',
    transfer_schedule_today: 'Transferir para hoy',
    transfer_schedule: 'Transferir',
    duplicate_schedule: 'Duplicar',
    security_stock: 'Stock de Seguridad',
    safety_stock_kikker: 'Stock de Seguridad Kikker',
    search: 'Buscar',
    select: 'Seleccione...',
    select_date: 'Seleccione la Fecha',
    sector: 'Sector',
    service_level: 'Nivel de Servicio',
    send_request: 'Enviar petición',
    sales_1_month_ago: 'Venta total -1 mes (unid. compra)',
    sales_2_month_ago: 'Venta total -2 meses (unid. compra)',
    sales_3_month_ago: 'Venta total -3 meses (unid. compra)',
    forecast_stock: 'Acciones previstas',
    current_order_value: 'Valor del pedido actual',
    coverage_suggested_by_kikker: 'Cobertura sugerida por Kikker',
    current_stock_days: 'Días de existencias actuales',
    mean_daily_adjusted_sale: 'Ventas promedio ajustadas (unid. venta)',
    changed_order: 'Cambiado manualmente',
    icon_change_order_plus: 'Cambiado +',
    icon_change_order_minus: 'Cambiado -',
    schedule: 'Agenda',
    schedules: 'Agendas',
    schedule_date: 'Fecha de la Agenda',
    similars: 'Similares',
    rupture_for_x_class: 'Considerar faltante para la clase X',
    order_for_all_suppliers_of_product_full_text: 'Generar pedido para todos los Proveedores del Producto',
    similars_products: 'Produtos Similares',
    configurations_synchronization: 'Configuraciones de Sincronización',
    configurations_password_update_time: 'Número de días para actualizar la contraseña',
    password_update: 'Actualización de contraseña',
    api_url: 'Direccion de Acceso',
    api_key: 'AutenticAccion',
    show_tuition_notice: 'Mostrar alerta predeterminada',
    configurations_predictions: 'Configuraciones de Previsiones',
    prediction_error_margin: 'Margen de error (%)',
    prediction_coefficients: 'Coeficientes para SMAPE en la predicción',
    seasonal_products: 'Productos Estacionales',
    configurations_order: 'Configuración de Pedido',
    quotation_api: 'API Cotización',
    api_schedule_url: 'API calendario',
    order_calc_by_coverage_days_option: 'Calculo en el pedido cuando tiene dias de cobertura',
    order_calc_by_coverage_days_options: {
      prediction: 'Suma de la prevision en los dias de cobertura (estandar)',
      avg_adjusted_sales: 'Media de venta ajustada X dias de cobertura',
      optional_selling_media_as_default: 'Opcional (Ventas promedio por defecto)',
    },
    order_pending_orders_until_date: 'Buscar pedidos pendientes',
    order_pending_orders_until_date_options: {
      all: 'Todos',
      coverage_initial_date: 'Até a data inicial da cobertura',
      coverage_final_date: 'Até a data final da cobertura',
    },
    unity_transfer_in_package: 'Unidad de Transferencia en Unidad de Compra? (CD -> Tienda)',
    order_calc_by_coverage_days_consider_safety_stock: 'Considerar estoque de segurança quando tiver dias de cobertura',
    consider_deadline_on_orders: 'Considere el tiempo de entrega cuando se llenan los días de cobertura',
    validate_price_on_orders: 'Validar precio mayor que cero al generar el pedido',
    permit_change_negotiated_price_on_order: 'Le permite cambiar el precio en el pedido',
    consider_blocked_product_in_order: 'Considere productos bloqueados',
    allow_remove_transfer_pending_order: 'Permitir eliminar orden de transferencia pendiente',
    always_send_blocked_product_on_order: 'Enviar siempre producto bloqueado',
    close_order_by_filtering: 'Cerrar orden teniendo en cuenta el filtrado',
    show_field_delivery_date: 'Mostrar campo fecha de entrega',
    include_disabled_dc: 'Incluir Centro de Distribucion deshabilitado',
    configuration_api_delivery_order: 'Entrega de Pedidos',
    get_order_code: 'API para obtener el código de pedido',
    api_delivery_order: 'Orden de vuelta',
    grouping_type: 'Tipo de Agrupamento',
    grouping_type_options: {
      none_grouping: 'sin agrupar',
      'systematic;store_id': 'Sistemático y Almacén',
    },
    delivery_order: 'Orden de entrega',
    movement_types: 'Classificación de los Movimiento',
    delivery_order_items_with_zero: 'Entregar artículos de pedido con cantidad cero',
    consider_unity_transfer: 'Considere la unidad de transferencia',
    system_default_data: 'Datos del sistema predeterminados',
    default_days_to_use_combat: 'Días estándar para usar el combate',
    draft_order_item_block_justification: 'Justificación para el bloqueo de elementos',
    for_stores: 'Para tienda(s)',
    products: 'Productos',
    show: 'Mostrar',
    showing_of: 'Mostrando desde',
    skip_all_blocks_of_purchase: 'Ignore la compra de todos los productos',
    skip_blocks_of_purchase: 'Ignore la compra',
    skip_block_of_purchase_modal_title:
      '¿Está seguro de que desea ignorar el' + ' bloqueo de compra del ERP de todos los artículos?',
    skip_block_of_purchase_modal_content: 'Al completar esta opción, no se deshará.',
    sku: 'SKU',
    skus: 'SKUs',
    count: 'Cantidad',
    recidivism: 'Reincidente',
    sales_unit_quantity: 'Cantidad Unid. Venta',
    store: 'Tienda',
    stores: 'Tiendas',
    store_code: 'Código de Tienda',
    stock_sale_unit: 'Stock (unid. venta)',
    stock_purchase_unit: 'Stock (unid. compra)',
    status: 'Status',
    summary: 'Resumen',
    standard_sorting: 'Orden estandar',
    close_order_rules: 'Reglas de orden de compra',
    order_configuration: 'Botões de Pedidos',
    opening_date: 'Fecha de Apertura',
    alias_for_sales_accounting: 'Alias venta',
    store_clusters: 'Grupo de Tiendas',
    minimum_order_by_store: 'Pedido mínimo por tienda',
    maximum_coverage_days: 'Días máximos de cobertura',
    maximum_coverage_day: 'Días máximos de cobertura',
    percentage_up: 'Porcentaje de cobertura aumenta',
    percentage_up_and_down: 'Cobertura porcentual arriba y abajo',
    percentage_down: 'Porcentaje de cobertura hacia abajo',
    rule_name: 'Nombre de la Regla',
    rule_kind: 'Tipo de la Regla',
    warning: 'Alerta',
    blocking: 'Bloquear',
    approving: 'Aprobación',
    distribution_system: 'Sistemática de distribucion',
    sub_group: 'Sub Grupo',
    supplier: 'Proveedor',
    suppliers: 'Proveedores',
    supplier_code: 'Código Proveedor',
    supplier_code_abrev: 'Cód. Proveedor',
    historical_data_descending_order: 'Orden del historial del producto - Orden descendente',
    erp_control_reopen_available: 'Control de reapertura de pedidos vía ERP',
    allow_send_order_by_base64: 'Habilitar el envío de solicitudes a través de base64',
    has_get_order_code: 'Necesita una API para obtener el código de pedido del ERP',
    supplier_name: 'Nombre del Proveedor',
    sort_by: 'Ordenar por',
    supply_abrev: 'Abast.',
    supply_type: 'Sistemática de Abastecimiento',
    supply_type_abbr: 'Sist. Abastecimiento',
    supply_details: 'Detalles de Abastecimiento',
    surplus_stock: 'Stock Excedente',
    surplus_stock_value: 'Valor Excedente del Stock',
    to_select: 'Seleccionar...',
    searching: 'Buscando...',
    systematic: 'Sistemática',
    front_line_capacity_alternative: 'Exposición Kikker',
    coverage_days: 'Días de cobertura',
    stock: 'Stock',
    structure: 'Estructura',
    table_configurations: 'Configuraciones de la Tabla',
    telephone: 'Teléfono',
    title: 'Título',
    to_filter: 'Filtrar',
    total: 'Total',
    total_virtual_stock_alert: 'Articulos totales',
    total_virtual_stock_alert_section: 'Total artículos M.Alto + Alto',
    total_sell_in: 'Total sell in',
    total_minus_sell_in: 'Total menos sell in',
    total_in_pallets: 'Total de Paletes',
    total_in_number_of_boxes_per_ballast: 'Total de camadas',
    total_order_in_purchase_unit: 'Total en Unid. Compras',
    total_sum: 'Suma Total',
    total_sell_in_sum: 'Suma Total Sell In',
    total_minus_sell_in_sum: 'Suma Total Menos Sell In',
    extensions: 'Extensiones',
    total_in_pallets_sum: 'Suma de Paletes Total',
    total_in_number_of_boxes_per_ballast_sum: 'Suma de Camadas Total',
    total_weight_sum: 'Suma de peso total',
    total_volume_sum: 'Suma de volume total',
    total_order_in_purchase_unit_sum: 'Suma das Unid. Compras Total',
    total_transfer_stock: 'Valor Total Stock de Transf.',
    total_stock_value: 'Valor Total del Stock',
    unit: 'Unidad',
    unit_price: 'Precio unitario',
    unit_price_sale_unit_br: 'Precio unitario<br />(unid. de venta)',
    units: 'Unidades',
    unit_management_cost: 'Coste de Gestión',
    unit_value_abrev: 'V. Unitario',
    until: 'hasta',
    unrealized_billing: 'Faturacion no realizada',
    update_schedule_question: 'Actualizar agenda?',
    origin: 'Origen',
    draft_order_audit: 'Cambios de orden',
    business_configuration: 'Configuración del Grupo Económico',
    value: 'Valor',
    original_value: 'Valor original',
    percentage_increase_in_total: 'Aumento porcentual en total',
    values: 'Valores',
    variation: 'Variación actual',
    variation_past_years: 'Variación',
    vertical: 'Vertical',
    visit_date: 'Fecha de visita',
    tolerance: 'Tolerancia',
    tolerance_type: 'Tipo de Tolerancia',
    v_t: 'V.T.',
    v_e: 'V.E.',
    week_days: 'Dias de la semana',
    weekly_sale_in: 'Venta semanal en',
    total_weight: 'Peso Total',
    weight: 'Peso',
    volume: 'Volumen',
    total_volume: 'Volume Total',
    consumption_current_month: 'Consumo total mês atual',
    consumption_last_30_days: 'Consumo total últimos 30 dias',
    consumption_1_month_ago: 'Consumo total -1 mês',
    consumption_2_month_ago: 'Consumo total -2 mês',
    consumption_3_month_ago: 'Consumo total -3 mês',
    consumption_current_month_purchase_unit: 'Consumo total del mes en curso (Unit. de compra)',
    consumption_last_30_days_purchase_unit: 'Consumo total últimos 30 dias (Unit. de compra)',
    consumption_1_month_ago_purchase_unit: 'Consumo total -1 mês (Unit. de compra)',
    consumption_2_month_ago_purchase_unit: 'Consumo total -2 mês (Unit. de compra)',
    consumption_3_month_ago_purchase_unit: 'Consumo total -3 mês (Unit. de compra)',
    without_prevision: 'Sin Prevision',
    with_stock_abbreviation: 'C/Stock',
    wholesale_unit_price: 'Precio al por mayor',
    product_complete: 'Producto terminado',
    promotional_unit_price: 'Precio Promocional',
    closing_order: 'cerrando el pedido',
    remove_item: 'Eliminar Item',
    remove: 'Eliminar',
    return_item: 'Volver Item del Pedido',
    change_prices: 'Modificar precios',
    buy_question: 'Comprar?',
    sale_question: 'Vender?',
    yes: 'Si',
    no: 'No',
    sdm_wholesale_unit_price: 'Precio al por mayor',
    sdm_retail_unit_price: 'Precio al por menor',
    sdm_gross_sales_unit_price: 'Precio bruto',
    sdm_promotional_unit_price: 'Precio Promocional',
    reference_data: 'Dato de Referencia',
    mix_question: 'Surtido?',
    order_loading: 'Orden...',
    yesterday: 'Ayer',
    week_abreviation: 'Sem.',
    weekly_sale: 'Venta Semanal',
    new_supplier: 'Nuevo Proveedor',
    is_alternative_supplier: 'Proveedor Alternativo',
    is_not_alternative_supplier: 'No es un Proveedor Alternativo',
    not_alternative_supplier: 'Este proveedor no es una alternativa, continuar?',
    draft_order_item: 'articulo ordenado',
    sale_unit_par: '(unid. venta)',
    purchase_unit_par: '(unid. compra)',
    suggested_par: '(sugerida)',
    actual_par: '(actual)',
    waiting: 'esperando',
    front_line_capacity: 'Capacidade de la exposición (un. venda)',
    rounding_percentage: 'Arredondamento da Unid. de Compra',
    rounding_for_store: 'Redondeo por tienda',
    order_unit: 'Tipo de compra',
    type_order: 'Tipo de pedido',
    schedule_style: 'Tipo de agenda',
    simulation_schedule: 'Simulación',
    default_schedule: 'Normal',
    blank_schedule: 'En blanco',
    round_order_unit: 'Redondeo de la unidad de Pedido',
    faq: 'FAQ',
    // full_capacity_pallet: 'Unidad del Pedido em Unidad de Compra',
    rounding_factor_in_purchase: 'Factor de multiplicacion Compra',
    rounding_factor_in_sale: 'Factor de multiplicacion en venta',
    pallet: 'Pallet',
    layer: 'Capa',
    pallet_or_layer: 'Paleta o capa',
    multi: 'Multiplo',
    order_number: 'Numero de Pedido',
    change_for_all: 'Cambiar para todos',
    synchronize_erp: 'Sincronizar ERP',
    job_managers_abbr: 'JMs',
    request: 'Requisicion',
    close_warning:
      'Al forzar el cierre de este modal es posible que el proceso se interrumpa' +
      ' antes de su finalización, por lo que se sugiere que se comprueben los datos del pedido.',
    response: 'Respuesta',
    click_here: 'Click aqui',
    and_send_the_informations_to_support: 'y envie la informacion a Soporte',
    slow_process: 'Proceso lento ?',
    cross_docking: 'Cross Docking',
    systematic_erp: 'Sistematica ERP',
    secondary_systematic: 'Sistematica Informada',
    distribution_center: 'Centro de Distribucion',
    running_for: 'Corriendo para',
    second: 'Segundo',
    seconds: 'Segundos',
    sell_in: 'Sell In',
    minute: 'Minuto',
    minutes: 'Minutos',
    hour: 'Hora',
    hours: 'Horas',
    and: 'y',
    previous_product: 'Producto anterior',
    next_product: 'Proximo producto',
    delete: 'Borrar',
    reopen_order: 'Reabrir Pedido',
    synced_at: 'Sincronizado el',
    disabled_beacause_has_closed_orders: 'Deshabilitado por tener orden cerrada.',
    contract_negotiations: 'Contratos negociados',
    product_family: 'Familia',
    product_families: 'Familias',
    deadline_erp: 'Tiempo de entrega ERP',
    schedule_deadline: 'Tiempo de entrega (agenda)',
    update_schedule: 'Actualizar agenda?',
    order_deadline: 'Tiempo de entrega (pedido)',
    max_deadline: 'Mayor tiempo de entrega (los artículos)',
    suggested_quantity: 'Cantidad sugerida',
    function: 'Función',
    automatic_purchase_items: ' artículos de compra automática',
    automatic_purchase: 'Compra Automática',
    removed_product_store: 'Producto Tienda Eliminado',
    full_capacity_pallet: 'Capacidad total palet',
    full_capacity_pallet_erp: 'Capacidad total palet ERP',
    client: 'Cliente',
    number_of_boxes_per_ballast: 'N. de cajas por balastro',
    participation: 'Participación',
    classification_configs: 'Configuraciones de clasificación de clase',
    number_of_boxes_per_ballast_erp: 'N. de cajas por balastro ERP',
    number_of_layers: 'N. de capas',
    number_of_layers_erp: 'N. de capas ERP',
    layers: 'Camadas',
    default: 'Patrón',
    order_for_all_suppliers_of_product: 'Considerar productos de suministro alternativos',
    or: 'o',
    start_date: 'Fecha de inicio',
    sending_erp: 'Envío de pedido a ERP',
    awaiting_erp: 'Esperando el regreso del ERP',
    finished_delivery_erp: 'Entrega completada a ERP',
    general_error: 'Error general en el pedido',
    partial_error: 'Error de pedido parcial',
    final_date: 'Fecha final',
    action: 'Acción',
    signal: 'Señal',
    remove_store: 'Remover tienda',
    remove_stores: 'Remover tiendas',
    process_with_errors: 'Processos com erro',
    return_store: 'Volver tienda',
    return_stores: 'Volver tiendas',
    block: 'Bloquear',
    unblock: 'Desbloquear',
    download_xlsx_of_removed_items: 'Descargar elementos eliminados o bloqueados',
    block_justification: 'Justificación',
    save_block_status: 'Seguir bloqueando para pedidos futuros',
    export_list: 'Exportar lista',
    delete_all_items: 'Eliminar todos los elementos',
    confirm_delete_all_items:
      'Todos los artículos se marcarán como eliminados del pedido' +
      ' para que solo los artículos deseados se puedan dedvolver en el pedido.',
    auto_close_order: 'Cierre automático de pedidos',
    in_maintenance: 'En Mantenimiento',
    in_maintenance_message: 'Mensaje de mantenimiento',
    clear_ignored_pending_orders: 'Borrar pedidos pendientes eliminados',
    apply_sistematics: 'Aplicar Sistemática',
    contract_priority_deadline: 'priorizar el plazo de entrega del contrato',
    change_all_items: 'Cambiar para todos los artículos',
    just_this_order: 'Solo por esta orden',
    coverage_of_product_is: 'la cobertura del producto es de %{days}.',
    coverage_for_store: 'Cobertura por tienda',
    return: 'Volver',
    consider_deadline_on_product: 'en el producto es a %{option}.',
    consider: 'considera',
    not_consider: 'no consideran',
    empty: 'vacío',
    show_lasts_trade_in: 'Muestra las últimas entradas',
    show_doi_changes: 'Muestra los cambios en los artículos del comprador restringido',
    filter: 'Filtrar',
    mirror_stores: 'Espejo de tiendas',
    mirror_store: 'Tienda espejo',
    base_quantity_is: 'La cantidad base es',
    delivered_code: 'Repartidor',
    accept: 'Acatar',
    percent_of_purchase: '% de compra',
    buy_store: 'Loja de compra',
    distributed: 'Distribuido',
    centralized: 'Centralizado',
    central_store: 'Tienda Central',
    requester_name: 'Nombre solicitante',
    final_stock: 'Stock',
    force_accept: 'Forzar aceptar',
    true: 'Sim',
    false: 'Não',
    error: 'Error',
    inform_error: 'Existen productos con error en el pedido',
    confirm_close_with_error: '¿Está seguro de que desea cerrar el pedido con errores?',
    errors: 'Errors',
    created_at: 'Creado en',
    shipping_times: 'Plazo de entrega',
    activity_calendars: 'Calendario de actividades',
    assign_products: 'Asignar productos a la tienda',
    supply_policies: 'Políticas de abastecimiento',
    status_historical: 'Histórico de alteraciones de status',
    order_settings: 'Configuración de pedidos',
    updated_at: 'Actualizado en',
    elapsed_time: 'Tiempo de Procesamiento',
    assigned_at: 'Asignado en',
    unassigned_at: 'Desasignado en',
    create: 'Crear',
    processing: 'Processando',
    refresh_in_sec: 'actualizar cada %{time}s',
    days_for_payment: 'Días para el pago',
    // expiration_date: 'Fecha de entrega',
    order_delivery_forecast: 'Previsión de Entrega de Pedido',
    order_code: 'Código de solicitud',
    order_codes_erp: 'Códigos de solicitud de erp',
    individual_change: 'Cambio individual',
    changes: 'Cambios',
    changed_by: 'Cambiado por',
    next_visit: 'Siguiente visita',
    extra_data: 'Dator extra',
    coverage_days_by_class: 'Días de cobertura por clase',
    set_by_class: 'Establecido por clase',
    back_to_simple_mode: 'Volver al modo simple',
    change_supplier: 'Cambiar Proveedor',
    update_order_in_purchase_unit: 'Actualizar unidad de compra',
    change_coverage: 'Cambiar cobertura',
    change_front_line_capacities: 'Cambiar la capacidad de la exposición',
    change_product_rounding: 'Cambiar redondeo de producto',
    disable_product_rounding: 'Desactivar redondeo de producto',
    enable_product_rounding: 'Activar redondeo de producto',
    change_seasonal_product: 'Producto de temporada',
    forecast_stock_less_than: 'Pronosticar inventário menos que',
    reorder_without_pending_orders: 'Regenerar pedido sin pedido pendiente',
    change_systematic: 'Cambio sistemático',
    change_deadline: 'Cambiar el tiempo de entrega',
    change_pallet_info: 'Cambiar la información del palet',
    change_days_to_use_in_avg: 'Días para considerar',
    change_combat_product: 'Producto de combate',
    type_combat_product: 'Tipo de producto de combate',
    combat_product_in_promotion: 'Los productos en combate no consideran promociones',
    consider_past_promotions: 'Promociones Anteriores',
    consider_constantly_on_sale: 'Promoción constante',
    consider_past_promotions_only_with_stock_or_sale: 'Promociones Anteriores Con Rebajas o Stock',
    rational_amount_apportionment: 'Reparto racional de compras',
    centralized_purchase: 'Compras centralizadas',
    change_block_items: 'Bloquear elementos',
    unblock_items: 'Desbloquear elementos',
    not_buy: 'ROJO - NO COMPRAR',
    buy: 'VERDE - Comprar',
    irregular_behavior: 'AZUL - Comportamiento irregular',
    sudden_sale: 'NARANJA - Aumento repentino de las ventas',
    review: 'AMARILLO - Revisión',
    processed: 'Procesada',
    block_for_purchase: 'Bloqueado para la compra',
    store_dependencies: 'Dependencias de tienda',
    main_store: 'Tienda principal',
    dependent_stores: 'Tiendas dependientes',
    product_transferred_another_supplier: 'Transferido de otro proveedor',
    product_transferred_for_supplier: 'Transferido a otro proveedor',
    show_supply_requests: 'Solicitud de reabastecimiento',
    show_delivery_info: 'Ver la información de entrega del pedido',
    bound: 'Enlace',
    unbound: 'Desatar',
    bound_status: 'Estado Obligado',
    supply_request_partially_bound: 'Parcialmente Vinculado',
    supply_request_multiple_statuses: 'Estados Múltiples',
    delete_request: 'Borrar Petición',
    supply_request_unbound: 'Sin consolidar',
    supply_request_bound_to_this: 'Vinculado a esto',
    supply_request_bound_to_other: 'Vinculado a otro',
    supply_request_unbounded_from_this: 'Desvinculado de esto',
    supply_request_pending: 'Pendiente',
    supply_request_accepting: 'En aceptación',
    supply_request_need_to_bound: 'Esperando enlace',
    supply_request_approved: 'Aprobado',
    supply_request_deleted: 'Borrado',
    data_request: 'Fecha de solicitud',
    time_request: 'Tiempo requerido',
    approved: 'Aprovado',
    awaiting: 'Aguardando',
    rejected: 'Reprovado',
    reject: 'Reprovar',
    approve: 'Aprovar',
    release_requests: 'Solicitud de aprovación',
    message: 'Mensaje',
    see_order: 'Ver pedido',
    update_time_request: 'Tiempo de actualizacion',
    allow_weekend: 'Permitir Fin de Semana',
    file: 'Ficheiro',
    batch_import: 'Importación por lotes',
    hint_to_batch_import_file: 'Seleccione el archive EXCEL descargado en el que se realizaron los ajustes de cantidad',
    line: 'Linea',
    store_stock: 'Estoque de produto',
    batch_importing: 'Importando en lote',
    rack_stock: 'Acción de la góndola',
    expiration_date: 'Fecha de caducidad',
    forced_to_use: 'Forzado a usar',
    readjust_order: 'Reajuste de pedido',
    readjust_order_direction: 'Mínimo/Máximo',
    readjust_order_value: 'Valor',
    round_order_unit_layer: 'Capa',
    just_this_order_days_to_use_in_avg: 'Solo por este pedido',
    clear_all_days_to_use_in_avg: 'Cambio para todos los artículos',
    days_to_use_in_avg: 'Número de días',
    start_date_to_use_in_avg: 'La fecha de inicio',
    final_date_to_use_in_avg: 'Datos finales',
    systematic_for_product: 'Sistemática por producto',
    systematic_for_supplier: 'Sistemática por Proveedor',
    secondary_delivered: 'Centro de distribución',
    rounding_for_product: 'Redondeo por producto',
    days_to_consider: 'Días a considerar',
    cockpit: 'Cabina',
    users: 'Usuarios',
    user: 'Usuario',
    calculation_method: 'Método de cálculo',
    default_distribution_center: 'Centro de distribución predeterminado',
    identification: 'Identificación',
    transform_schedule: 'Habilitar cierre',
    enable_close: 'Habilitar agenda para cierre de compra',
    send_solicitation: 'Enviar solicitacion',
    simulation_count: 'Agendas de simulación',
    orders_count: 'Agendas totales',
    orders_count_default: 'Agendas normales',
    closed_orders_count: 'Agendas cerradas',
    closed_orders_percent: 'Agendas cerradas (%)',
    ddv_calculation_type: 'Calculo de DDV',
    advanced_supplier: 'Proveedor avanzado',
    centralized_store_without_product: 'Tienda centralizada sin producto',
    systematic_not_distribution_center: 'Sistemática no puede ser centro de distribución',
  },
  activity_calendar: {
    edit_activity_calendar: 'Editar Calendario de Actividades',
    date: 'Fecha',
    without_sale: 'Sin Venta',
    without_order: 'Sin Pedido',
    without_delivery: 'Sin Entrega',
    errors: {
      date: 'Fecha es un campo obligatorio',
      activity_params: 'Actividad es un campo obligatorio',
      error: 'Error al guardar el calendario de actividades',
    },
  },
  calendar: {
    date_format: {
      default: 'd/m/Y',
    },
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miercoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
    abbr_day_names: {
      sunday: 'Dom',
      monday: 'Lun',
      tuesday: 'Mar',
      wednesday: 'Mie',
      thursday: 'Jue',
      friday: 'Vie',
      saturday: 'Sáb',
    },
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
    abbr_month: {
      jan: 'Ene',
      feb: 'Feb',
      mar: 'Mar',
      apr: 'Abr',
      may: 'May',
      jun: 'Jun',
      jul: 'Jul',
      aug: 'Ago',
      sep: 'Set',
      oct: 'Oct',
      nov: 'Nov',
      dec: 'Dic',
    },
    month: 'Mês',
    day: 'Dia',
    week: 'Semana',
    schedule: 'Agenda',
  },
  calculation_method: {
    avg_adjusted_sales: 'Venta ajustada',
    prediction: 'Pronóstico',
  },
  periodicities: {
    unique_schedule: 'Agenda única',
    in_days: 'En dias',
    weekly: 'Semanal',
    biweekly: 'Quincenal',
    monthly: 'Mensual',
    bimonthly: 'Bimestral',
    quarter: 'Trimestral',
  },
  charts: {
    activity: 'Atividade del Surtido',
    activity_days: 'Alerta de Stock Virtual',
    days_without_sales: 'Dias sin venta',
    ruptures: 'Faltante comercial',
    ruptures_logistics: 'Faltante Logistica',
    negative: 'Stock Negativo',
    last_30: 'Stock Excedente',
    virtual_stock_alerts: 'Alerta de stock virtuales',
    abc_shares: 'Participacion Surtido',
    ruptures_alert: 'Alerta de Faltantes',
    abc_curve: 'Curva ABC',
    top_sales: 'Top Ventas',
    cost_variation: 'Variacion de Costo',
    sale_variation: 'Variacion de Venta',
    cost_variation_abbreviation: 'Var. costo',
    sale_variation_abbreviation: 'Var. venta',
    top_ruptures: 'Top Faltantes',
    top_ruptures_in_value: 'Top Faltantes en Valor',
    top_ruptures_in_quantity: 'Top Faltantes en Cantidad',
    top_ruptures_in_incidence: 'Top Faltantes en Repeticiones',
    top_breaks_in_value: 'Top Mermas en Valor',
    top_breaks_in_quantity: 'Top Mermas en Cantidad',
    top_breaks_in_incidence: 'Top Mermas en Repeticiones',
    top_breaks: 'Top Mermas',
    virtual_alerts: 'Faltante Operativo',
    in_value: 'en Valor',
    recidivism: 'en Repeticiones',
    lost_products: 'en Cantidad',
    control_panel: 'Panel de Control',
    indicators: 'Indicadores',
    performance: 'Performance',
    ordering_suggestions: 'Sugerencia de pedidos',
    transversal_view: 'Vision Transversal',
    continuous_improvement_action: 'Accion de Mejora Continua',
    listing: 'Listado',
    product_history: 'Histórico de Producto',
    virtual_stock_alerts_classification: {
      '1- HIGHEST': 'Muy alto',
      '2- HIGH': 'Alto',
      '3- AVERAGE': 'Medio',
      '4- LOW': 'Bajo',
    },
  },
  charts_info: {
    activity: {
      in_line: 'linea',
      for_purchase: 'compra',
      without_stock: 's/stock',
      without_order: 's/orden',
      inactive: 'inactivo',
      without_schedule: 's/agenda',
      without_sale: 's/venta',
      all: 'todo',
    },
    activity_days: {
      d7: 'd7',
      d30: 'd30',
      d60: 'd60',
      d90: 'd90',
      'd+': 'd+',
    },
    days_without_sales: {
      d7: 'd7',
      d30: 'd30',
      d60: 'd60',
      d90: 'd90',
      'd+': 'd+',
    },
    ruptures_alert: {
      all: 'Todo',
      supplied_by_cd: 'Abastecido por CD',
    },
  },
  filters: {
    nothing_found: 'Ningun registro encontrado',
    select_one_bg: 'Seleccione un grupo economico',
    type_to_search: 'Tipee para buscar',
    all_products: 'Todos los productos',
    only_launch: 'Solo lanzamiento',
    no_launch: 'Sin lanzamiento',
    confirm_next_visit: 'Confirmar próxima visita',
    open_schedule: 'Abrir agenda',
    edit_schedule: 'Editar agenda',
    synchronize_erp: 'Sincronizar ERP',
    click_for_open: 'click para abrir',
    only_promotion: 'Solo Promocion',
    no_promotion: 'Sin Promocion',
  },
  modal: {
    title_expand_line: 'Abrir resumen mensual de producto',
    title_collapse_line: 'Cerrar resumen mensual de producto',
    product_with_invalid_data: 'Producto con datos inválidos',
    delivery_item_erp: 'Entrega del item en el ERP',
    last_item_delivered_erp: 'Último item entreguado en el ERP en',
  },
  validations: {
    required: 'Campo requerido',
  },
  alerts: {
    dont_have_data_toggle_property: 'No posee propiedad data-toggle',
    synchronize_database:
      'Desea sincronizar los datos de los productos de esos pedidos' +
      ' con el ERP? <i>(el pedido será regenerado)</i>',
    loading_charts: 'Error cargando datos del gráfico, intente nuevamente',
    changed_coefficients: 'Coeficientes fueron cambiados, desea continuar? Los cambios seran perdidos',
    not_records: 'No existen registros',
    select_an_action: 'Seleccione una opcion',
    select_one_or_more_options: 'Seleccione una o mas opciones',
    product_not_found: 'Ningun producto encontrado',
    searching_for: 'Buscando por',
    select_economic_group: 'Seleccione un grupo economico',
    type_to_search: 'Tipee para buscar',
    not_found: 'Ningun registro encontrado',
    not_record_for: 'Ningun resultado para:',
    supplier_not_found: 'Ningun proveedor encontrado',
    all_stores_selected: 'Si no se selecciona ninguna tienda, se cambiarán todas las tiendas.',
    store_not_found: 'Ninguna tienda encontrada',
    regional_not_found: 'Ninguna region encontrada',
    select_stores: 'Seleccione las tiendas',
    code_supllier_name: 'Código o nombre del proveedor...',
    updating_information: 'Atualizando informaciones',
    calculating_orders: 'Calculando pedidos',
    getting_information: 'Obteniendo informaciones',
    inform_support: 'Informe a soporte:',
    connection_error: 'Error de conexion',
    processing_error: 'Ocurrio un error en el procesamiento',
    generating_order: 'Generando pedidos',
    unsupported_browser: 'Navegador no compatible',
    no_result_for_the_period: 'Sin resultado para el período',
    send_email_xls: 'Estamos procesando su solicitacion y enseguida enviaremos el archivo XLS para su email: ',
    lost_connection_try_again: 'Perdida de conexion. Intente nuevamente en instantes.',
    price_table_not_found: 'No existe tabla de precio para ese producto.',
    confirm_delete_schedule: 'Realmente desea eliminar la agenda?',
    confirm_save_duplicate_schedule: '¿Quieres regenerar o duplicar la Agenda?',
    first_warn_before_delete:
      ' Si la agenda ya está cerrada,' + ' permanecerá cerrada y solo se eliminará la próxima agenda.',
    second_warn_before_delete:
      'Si la agenda no se ha cerrado,' +
      ' la solicitud será eliminado, el calendario actual y el siguientes también se eliminarán.',
    ask_to_reopen_order: 'Realmente desea reabrir este pedido?',
    reopen_order_warning:
      'Si el pedido ya está' + ' integrado con el ERP, deberá cancelarlo para que no haya duplicados.',
    rounding_factor_required: 'Factor de multiplicación o Factor de multiplicación en ventas es obligatorio',
    delete_request: '¿Realmente desea eliminar la solicitud?',
    coverage_limit: 'Su límite de cobertura es', // + ${}
    no_results: 'No hay resultados',
    saving_schedule: 'Guardando calendario',
    creating_schedule: 'Criando calendario',
    product_similarities: 'Productos con Similaridades',
  },
  pluralize: {
    purchaseunit: '{value} {value, plural, one {un.} =0 {un.} other {un.} }',
    unit: '{value} {value, plural, one {un.} =0 {un.} other {un.} }',
    days: '{value} {value, plural, one {dia} =0 {dia} other {dias} }',
    value: '{value, number, money}',
  },
  tabs: {
    averageStock: 'Stock medio',
    consumption: 'Consumo',
    tradeIn: 'Compras',
    actualSales: 'Ventas',
    break: 'Mermas',
    ruptures: 'Faltantes',
    inventoryAdjustment: 'Ajuste Inventario',
  },

  momentjs: {
    months: 'enero_febrero_marzo_abril_mayo_junio_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
    monthsShort: 'ene_feb_mar_abr_may_jun_jul_ago_sep_oct_nov_dic'.split('_'),
    weekdays: 'domingo_lunes_martes_miércoles_jueves_viernes_sábado'.split('_'),
    weekdaysShort: 'dom._lun._mar._mié._jue._vie._sáb.'.split('_'),
    weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
    weekdaysParseExact: true,
    longDateFormat: {
      LT: 'H:mm',
      LTS: 'LT:ss',
      L: 'DD/MM/YYYY',
      LL: 'D [de] MMMM [de] YYYY',
      LLL: 'D [de] MMMM [de] YYYY LT',
      LLLL: 'dddd, D [de] MMMM [de] YYYY LT',
    },
    calendar: {
      sameDay: function () {
        return '[hoy a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
      },
      nextDay: function () {
        return '[mañana a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
      },
      nextWeek: function () {
        return 'dddd [a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
      },
      lastDay: function () {
        return '[ayer a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
      },
      lastWeek: function () {
        return '[el] dddd [pasado a la' + (this.hours() !== 1 ? 's' : '') + '] LT';
      },
      sameElse: 'L',
      month: 'Mes',
      day: 'Día',
      week: 'Semana',
      schedule: 'Calendario',
    },
    relativeTime: {
      future: 'en %s',
      past: 'hace %s',
      s: 'unos segundos',
      ss: '%d segundos',
      m: 'un minuto',
      mm: '%d minutos',
      h: 'una hora',
      hh: '%d horas',
      d: 'un día',
      dd: '%d días',
      M: 'un mes',
      MM: '%d meses',
      y: 'un año',
      yy: '%d años',
    },
    ordinalParse: /\d{1,2}º/,
    ordinal: '%dº',
  },
  number: {
    money: {
      style: 'currency',
      currency: 'ARG',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    rounded: {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
    symbols: {
      decimal: ',',
      thousand: '.',
    },
  },
  ascDescSelector: {
    up: 'Orden Creciente',
    down: 'Orden Decreciente',
    default: 'Cancelar Orden',
  },
  product_supply_type: {
    supplier: 'Entregue por el Proveedor',
    supplier_abbr: 'Entreg. Prov.',
    supplier_for_print: 'PROVEEDOR',
    cross_docking: 'Entregue por el CD - Cross-Docking',
    cross_docking_abbr: 'Cross-Docking',
    cross_docking_for_print: 'CROSS-DOCKING',
    distribution_center: 'Entregue por el CD',
    distribution_center_abbr: 'Entreg. CD',
    distribution_center_for_print: 'CD',
  },
  movement_type_actions: {
    ignore: 'Ignorar',
    trade_out: 'venta',
    trade_in: 'Recepcion',
    devolution: 'Devolucion',
    inventory: 'Inventario',
    break: 'Merma',
    break_by_validity: 'Merma por validad',
    atypical_sale: 'Venda Atípica',
  },
  tax_informations: {
    purchase_price_simulator: 'Simulación de precio de compra',
    supplier_code: 'Código del Proveedor',
    supplier_name: 'Nombre del Proveedor',
    buying_price: 'Precio de Compra',
    in_tax_rate: 'Tasa de Impuestos',
    net_cost: 'Costo Neto',
    sell_in: 'Sell In',
    managing_fee: 'Tarifa de Gestión',
    margin: 'Margen',
    out_tax_rate: 'Tasa de Impuestos',
    sell_price_with_margin: 'Precio de Venta con Margen',
    sell_price_without_margin: 'Precio de Venta sin Margen',
    reference_price: 'Precio de Referencia',
    foreseen_margin: 'Margen Previsto',
    buying_ideal_cost: 'Costo Ideal de Compra',
    price_ideal_cost: 'Precio Ideal de Compra',
    suggested_sell_in: 'Sell In Sugerido',
    destination_location: 'Destino',
    origin_location: 'Origen',
    contract_negotiation_contract_code: 'Código do Contrato',
    contract_negotiation_contract_description: 'Descripción del contrato',
    sku_total: 'Total de SKUs',
    total_purchase_value: 'Valor de Compra',
    in_tax_total_value: 'Valor de Impuestos de Entrada',
    net_cost_total: 'Costo Neto',
    total_sell_in: 'Sell In',
    managing_fee_total: 'Tarifa de Gestión',
    margin_value_total: 'Margen',
    margin_value_total_with_sell_in: 'Margen con Sell In',
    out_tax_rate_total: 'Impuestos de Salida',
    total_value_sell_price: 'Precio de Venta',
  },
  navbar: {
    business_groups: 'Grupos Economicos',
    batch_imports: 'Importaciones',
    product_units: 'Unidades de Producto',
    holiday_calendars: 'Calendario de feriados',
    configurations: 'Configuración',
    product_similarities: 'Productos con Similaridades',
    cellphone_contact: ' (19) 99386-7790 ',
    account: 'Cuenta',
    signout: 'Cerrar sesión',
  },
  holiday_calendar: {
    name: 'Nombre',
    kind: 'Tipo de Feriado',
    fixed_date: 'Fecha Fija',
    uf: 'UF',
    city: 'Ciudad',
    dates: 'Fecha',
    kinds: {
      national: 'Nacional',
      state: 'Provincial',
      city: 'Municipal',
    },
  },
  forms: {
    rules: {
      required: 'Este campo es obligatorio',
      email: 'Ingrese un email válido',
      invalid_confirm_password: 'Las contraseñas no coinciden',
      market_structure_required: 'Estructura Merceológica es obligatorio',
    },
  },
  roles: {
    administrator: 'Administrador',
    monitor: 'Monitor',
    manager: 'Gestor',
    regional: 'Regional',
    store: 'Tienda',
    salesman: 'Vendedor',
    consultant: 'Consultor',
    buyer: 'Comprador',
    supplier: 'Proveedor',
    restricted_buyer: 'Comprador restringido',
    admin_client: 'Admin cliente',
    product_family: 'Familia',
  },
  api_log: {
    id: 'ID',
    url: 'URL',
    http_status: 'HTTP status',
    created_at: 'Creado a las',
    finished_at: 'Terminado a las',
    header: 'Header',
    data: 'Adicional',
    body: 'Cuerpo',
    response: 'Respuesta',
    method: 'Método',
  },
  request_log: {
    user: 'Usuario',
    controller_name: 'Controladora',
    action_name: 'Acción',
    method: 'Método',
    params: 'Parámetros',
    envs: 'ENVs',
    created_at: 'Creado en',
    id: 'Código',
  },
  job_managers: {
    status: 'Status',
    created_at: 'Creado a las',
    finished_at: 'Terminado a las',
    origin: 'Origen',
    parent: 'Predecesor',
    progress: 'Progreso',
    status_values: {
      processing: 'Procesando',
      finished: 'Finalizado',
      with_error: 'Con error',
    },
  },
  addons: {
    exposure_type: 'Tipo de exposición',
    business_inteligence_abbreviation: 'BI',
    budgetary_plan: 'Plan presupuestario',
    buyers: 'Compradores',
    documentation: 'Documentación',
    quizzer: 'Quizzer',
    workflow: 'Workflow',
    manager_panel: 'Panel de gestión',
    analytics: 'Analytics',
    pricing: 'Precios',
    suppliers: 'Proveedores',
    vision: 'Visión',
    tabloid: 'Tabloide',
    quotation: 'Cotización',
    resupplier: 'Ressuprimento',
    pallets: 'Paletas',
  },
  combat_product: {
    error: {
      message: 'El tipo de producto de combate es obligatorio para los productos marcados como producto de combate',
    },
  },
  promotion_product: {
    error: {
      type_message: 'El tipo de producto promocional es obligatorio para productos marcados como producto promocional',
      value_message:
        'El valor del producto promocional es obligatorio para productos marcados como producto promocional',
    },
  },

  mirror_stores_form: {
    origin_store: 'Tienda de origen',
    destination_store: 'Tienda de destino',
    initial_date: 'Fecha inicial',
    final_date: 'Fecha final',
    multiplication_factor: 'Factor de multiplicación',
    errors: {
      initial_date: 'La fecha inicial es obligatoria',
      final_date: 'La fecha final es obligatoria',
      multiplication_factor: 'El factor de multiplicación es obligatorio',
      origin_store: 'La tienda de origen es obligatoria',
      destination_store: 'La tienda de destino es obligatoria',
    },
  },
  similarities: {
    error: {
      empty_products: 'No se encontraron productos para la similitud',
    },
  },
  classification_configs: {
    error: {
      error: 'Error',
      sum_error: 'La suma de los valores de las clases debe ser 100%',
    },
  },
  stores_form: {
    required: {
      regional: 'Regional es obligatorio',
      identification: 'Identificación es obligatorio',
      store_code: 'Código de la tienda es obligatorio',
      trade_name: 'Nombre comercial es obligatorio',
      address: 'Dirección es obligatorio',
      address_number: 'Número es obligatorio',
      zip_code: 'Código postal es obligatorio',
      country: 'País es obligatorio',
      state: 'Estado es obligatorio',
      city: 'Ciudad es obligatorio',
      room_size: 'Tamaño de la sala es obligatorio',
      checkout_count: 'Número de cajas es obligatorio',
      manager_name: 'Nombre del gerente es obligatorio',
      manager_email: 'Correo electrónico del gerente es obligatorio',
      telephone: 'Teléfono es obligatorio',
      mobile_phone: 'Celular es obligatorio',
      negotiated_value: 'Valor negociado es obligatorio',
    },
  },
  palletsError: {
    tooltip: 'Error en la configuración del palet',
    title: 'Itens con error',
    not_informed: 'No informado o cero',
  },
  errors: {
    regionals: {
      delete: 'Error al eliminar regional',
    },
    product_units: {
      delete: 'Error al eliminar unidad de producto',
    },
    create: 'Error al crear!',
    update: 'Error al actualizar!',
  },
  success: {
    create: 'Creado exitosamente!',
    update: 'Actualizado exitosamente!',
  },
};
